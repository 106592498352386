import React, { useContext, useState } from 'react';
import { requestStatus } from '../common/requestStatus';
import { useContact } from '../contact';
import { definedOrThrows } from '../utils/definedOrThrows';
import { isLatin } from './isLatin';
import { AddressVerificationStatus, useValidateAddress } from './useValidateAddress';

interface Context {
  inputIsLatin: latinCheck;
  vatStatus: requestStatus;
  setVatStatus: (status: requestStatus) => void;
  addressVerification: AddressVerificationStatus;
}

interface latinCheck {
  firstName: boolean;
  lastName: boolean;
  address: boolean;
  city: boolean;
  company: boolean;
}

export const BillingContext = React.createContext<Context | undefined>(undefined);

export const BillingContextProvider: React.FC = ({ children }) => {
  const { firstName, lastName, company, city, address } = useContact().contact;
  const [vatStatus, setVatStatus] = useState<requestStatus>(requestStatus.EMPTY);
  const addressVerification = useValidateAddress();

  const inputIsLatin = {
    firstName: isLatin(firstName),
    lastName: isLatin(lastName),
    address: isLatin(address),
    city: isLatin(city),
    company: isLatin(company),
  };

  const data: Context = {
    vatStatus,
    inputIsLatin,
    addressVerification,
    setVatStatus,
  };
  return <BillingContext.Provider value={data}>{children}</BillingContext.Provider>;
};

export const useBillingContext = () => {
  const context = useContext(BillingContext);
  return definedOrThrows(context, 'No Billing Context');
};
