import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext } from 'react';
import { definedOrThrows } from '../utils/definedOrThrows';

/**
 * @desc Stores screen dimensions of device
 */

const MediaQueries = React.createContext<{
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
}>({});

const MediaQueryProvider = React.memo(({ children }) => {
  const desktop = useMediaQuery('(min-width:960px)');
  const mobile = useMediaQuery('(max-width:600px)');

  const values = {
    mobile,
    tablet: !mobile && !desktop,
    desktop,
  };
  return <MediaQueries.Provider value={values}>{children}</MediaQueries.Provider>;
});

const useMediaQueries = () => {
  const context = useContext(MediaQueries);
  return definedOrThrows(context, 'Could not load context');
};

export { MediaQueryProvider };
export default useMediaQueries;
