import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './font.css';
import './i18n/i18n';
import './index.css';
import './monitoring/monitoring';

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
