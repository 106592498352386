import { useContact } from '../contact';
import { useDiscount } from '../discount';
import { usePreview } from '../preview';
import { useSubscription } from '../subscription';
import { useUserSettings } from '../userSettings';
import { useMakeNewSubscription } from './useMakeNewSubscription';
import webshopSubscribe from './webshopSubscribe';

export const useWebshopSubscribe = () => {
  const { contact } = useContact();
  const { subscription } = useSubscription();
  const { promoCode } = useDiscount();
  const { preview } = usePreview();
  const { userSettings } = useUserSettings();

  return useMakeNewSubscription((paymentMethod) =>
    webshopSubscribe(
      {
        contact,
        newSubscription: subscription,
        promoCode,
        preview,
        userSettings,
      },
      paymentMethod,
    ),
  );
};
