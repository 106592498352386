import { useEffect, useRef } from 'react';

export const useDisabler = (enable: () => void, disable: () => void, isDisabled?: boolean) => {
  const lastConditionRef = useRef(false);

  useEffect(() => {
    const changed = lastConditionRef.current !== isDisabled;
    lastConditionRef.current = Boolean(isDisabled);
    if (changed && lastConditionRef.current) {
      disable();
    }
    return () => {
      lastConditionRef.current && enable();
      lastConditionRef.current = false;
    };
  }, [enable, disable, lastConditionRef, isDisabled]);
};
