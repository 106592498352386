import { BillingCycle } from '@webdev/product-code';
import React, { useEffect } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { CustomBackdrop } from '../common/CustomBackdrop';
import { RightDrawer } from '../common/RightDrawer';
import { useContact } from '../contact';
import { Currency } from '../currency';
import { useDiscount } from '../discount';
import { paths } from '../navigation/UrlPaths';
import { useSubscription } from '../subscription';
import { useUserSettings } from '../userSettings';
import { useWebshopMessage } from '../webshopMessages';
import { Cart } from './Cart';
import { ContainerBox } from './styling';

export interface IAddToCartMessage {
  type: 'addtocart';
  ratePlanId: string;
  name: string;
  billingCycle?: BillingCycle;
  promoCode?: string | null;
  currency: Currency;
  country: string;
}

declare global {
  interface WebshopMessageMap {
    addtocart: IAddToCartMessage;
  }
}

export const WebshopRoutes = () => {
  const { subscription, addSubscription } = useSubscription();
  const { setDiscount } = useDiscount();
  const { setContact } = useContact();
  const { setDefaultCurrency } = useUserSettings();
  const isMaybeCart = useRouteMatch(paths.CART);
  const open = [isMaybeCart].some(Boolean);
  const isFoldedDrawerShown = subscription.length > 0;
  const addToCartMessage = useWebshopMessage('addtocart');
  const history = useHistory();

  useEffect(() => {
    if (addToCartMessage) {
      const { ratePlanId, name, billingCycle, promoCode, currency, country } = addToCartMessage;

      setContact({ country });
      setDefaultCurrency(currency);
      addSubscription({ ratePlanId, name, billingCycle });
      setDiscount({ promoCode: promoCode || undefined });

      history.push(paths.CART);
    }
  }, [addToCartMessage, history, setContact, setDefaultCurrency, setDiscount, addSubscription]);

  return (
    <CustomBackdrop open={open} aria-label={open ? 'open backdrop' : 'closed backdrop'}>
      <RightDrawer open={open} isFoldedDrawerShown={isFoldedDrawerShown}>
        <ContainerBox>
          <Route path={paths.CART} component={Cart} />
        </ContainerBox>
      </RightDrawer>
    </CustomBackdrop>
  );
};
