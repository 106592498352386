import { Box, Grid, makeStyles, styled, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { Paper } from '../common/Paper';
import { Currency } from '../currency';
import { colors, fonts } from '../ui';
import ProductSwitch from './ProductSwitch';
import { PaidProduct } from './types';

const useStyles = makeStyles({
  toggleText: {
    color: 'white',
  },
});

interface AltLicenseSubtitleProps extends WithTranslation {
  showAlt: boolean;
  toggleProduct: () => void;
}

export const AltLicenseSubtitle = withTranslation()<AltLicenseSubtitleProps>(
  ({ showAlt, toggleProduct, t }) => {
    const classes = useStyles();

    return (
      <>
        <Typography component='span' className={classes.toggleText}>
          {t('Month')}
        </Typography>
        <ProductSwitch
          checked={!showAlt}
          onChange={toggleProduct}
          color='default'
          inputProps={{ 'aria-label': 'fixed license billing model switch' }}
        />
        <Typography component='span' className={classes.toggleText}>
          <Typography
            component='span'
            color='primary'
            style={{
              whiteSpace: 'nowrap',
              marginTop: '-20px',
              position: 'absolute',
              fontWeight: 700,
            }}
          >
            {t('savingsText')}
          </Typography>
          {t('Year')}
        </Typography>
      </>
    );
  },
);

interface PriceProps {
  currency: Currency;
  product: PaidProduct;
}

const getMonthlyPrice = (pricePerSeat: number, billingCycle?: string) => {
  switch (true) {
    case billingCycle === 'AN':
      return parseFloat((pricePerSeat / 12).toFixed(2));
    case billingCycle === 'MO':
      return pricePerSeat;
    default:
      return 0;
  }
};

export const Price: React.FC<PriceProps> = ({ product, currency }) => {
  const { t, i18n } = useTranslation();
  const { pricing, discount, productDescription } = product;
  const pricePerSeat = pricing.find((p) => p.currency === currency)?.price ?? 0;
  const discountPercentage = discount?.find((p) => p.currency === currency)?.percentage;
  const formatter = Intl.NumberFormat(i18n.language, { currency, style: 'currency' });

  const discountedPrice = (pricePerSeat * (100 - (discountPercentage || 0))) / 100;
  const discountedMonthlyPrice = getMonthlyPrice(discountedPrice, productDescription?.billingCycle);
  const pricePerMonth = getMonthlyPrice(pricePerSeat, productDescription?.billingCycle);

  return (
    <>
      <Box display='inline-block'>
        <PriceSmall>
          {formatter.format(discountedMonthlyPrice)} / {t('month')}
        </PriceSmall>
      </Box>
      {discountPercentage && (
        <Box display='inline-block'>
          <Box>
            <DiscountedPercentage>
              {t('Save percentage', { discountPercentage })}
            </DiscountedPercentage>
          </Box>
          <Box>
            <DiscountedTypography>{formatter.format(pricePerMonth)}</DiscountedTypography>
          </Box>
        </Box>
      )}
      {productDescription?.billingCycle === 'AN' && (
        <DescriptionTypography>
          {t('Billed yearly', { yearlyAmount: formatter.format(discountedPrice) })}
        </DescriptionTypography>
      )}
      {productDescription?.billingCycle === 'MO' && (
        <DescriptionTypography>{t('Billed monthly')}</DescriptionTypography>
      )}
    </>
  );
};

export const LicenseMiddleBox = styled(Box)({
  '#root &': {
    padding: 20,
    color: '#000000',
  },
});

export const LicenseBottomBox = styled(Box)({
  '#root &': {
    height: 150,
    padding: 20,
    position: 'relative',
  },
});

export const LicenseTitle = styled(Typography)(
  {
    '#root &': {
      color: 'white',
      fontWeight: 700,
      fontFamily: fonts.title,
    },
  },
  { name: 'LicenseTitle' },
);
LicenseTitle.displayName = 'LicenseTitle';

export const PriceSmall = styled('span')({
  '#root &': {
    fontFamily: fonts.text,
  },
});

export const BillingPeriod = styled('span')({
  '#root &': {
    fontFamily: fonts.text,
    whiteSpace: 'nowrap',
  },
});

export const DescriptionTypography = styled(Typography)({
  '#root &': {
    fontFamily: fonts.text,
    color: '#000000',
  },
});

export const AlignBottom = styled('div')({
  '#root &': {
    bottom: 24,
    '@media (max-width: 599px)': {
      // mobile
      position: 'unset',
    },
  },
});

export const CardWrapper = styled(Paper)({
  '#root &': {
    marginBottom: 0,
    borderRadius: 0,
    '@media (max-width: 599px)': {
      // mobile
      margin: 8,
      marginBottom: 100,
    },
  },
  '@media (min-width: 961px)': {
    // desktop
    margin: '0 12px',
  },
});

export const LicenseTopBox = styled(Grid)({
  '#root &': {
    padding: '0 30px',
    height: 'auto',
  },
});

export const DiscountedTypography = styled('span')({
  '#root &': {
    fontFamily: fonts.text,
    fontWeight: 500,
    color: colors.primaryGreySlate,
    fontSize: '1.5em',
    textDecoration: 'line-through',
    lineHeight: '1.5',
  },
});

export const DiscountedPercentage = styled(Typography)({
  '#root &': {
    color: colors.primaryEnscapeOrange,
    whiteSpace: 'nowrap',
    fontWeight: 700,
  },
});
