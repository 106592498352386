import { useStoreActionDispatcher, useStoreKeyState } from '../context/storeContext';
import {
  setBearerToken,
  setDefaultCurrency,
  setUserIsLoggedIn,
  setUserIsLoggedOut,
} from './actions';
import { UserSettingsState } from './state';

export const useUserSettings = () => ({
  userSettings: useStoreKeyState<UserSettingsState>('userSettings'),
  setUserIsLoggedOut: useStoreActionDispatcher(setUserIsLoggedOut),
  setDefaultCurrency: useStoreActionDispatcher(setDefaultCurrency),
  setBearerToken: useStoreActionDispatcher(setBearerToken),
  setUserIsLoggedIn: useStoreActionDispatcher(setUserIsLoggedIn),
});
