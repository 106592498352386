import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { baseURL } from '../api/baseUrl';
import { sessionId } from '../session/makeSessionId';
import { beforeSend } from './beforeSend';
import { sentryEnabled } from './sentryEnabled';

Sentry.init({
  enabled: sentryEnabled(),
  environment: process.env.FE_ENVIRONMENT,
  dsn: 'https://0557793ff1c140839b3117bcd7b1329e@o402106.ingest.sentry.io/5262664',
  integrations: [new CaptureConsole({ levels: ['error'] })],
  allowUrls: [baseURL],
  initialScope: (scope) => scope.setTag('sessionId', sessionId),
  ignoreErrors: [
    'Uncaught TypeError: can\'t access property "split", (intermediate value).slice(...).pop() is undefined', // gtm
    "undefined is not an object (evaluating '(/(?:^|; )__utmz=([^;]*)/.exec(document.cookie)||[]).slice(1).pop().split')", // gtm
    '(intermediate value).slice(...).pop() is undefined', // gtm
    'edge Translation started', // edge translation service
  ],
  beforeSend,
});

export const captureException = Sentry.captureException;

export const setMonitoringUserId = (zuoraId: string | null) =>
  Sentry.setUser(zuoraId ? { zuoraId } : null);
