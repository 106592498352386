import { ReducerBuilder } from '../context/reducerBuilder';
import { resetState } from '../context/resetState';
import {
  addSubscription,
  removeSubscription,
  setSeats,
  setSubscribeSuccess,
  setSubscription,
} from './actions';
import { defaultNewSubscription, NewSubscription } from './state';

export const subscriptionReducer = new ReducerBuilder<NewSubscription>()
  .case(addSubscription, (state, payload) => state.concat({ ...payload, seats: 1 }))
  .case(setSubscription, (state, { subscription, index }) => {
    const copy = [...state];
    copy[index] = { ...copy[index], ...subscription };
    return copy;
  })
  .case(setSeats, (state, { seats, index }) => {
    const copy = [...state];
    copy[index] = { ...copy[index], seats };
    return copy;
  })
  .case(removeSubscription, (state, index) =>
    state.filter((_, currentIndex) => currentIndex !== index),
  )
  .case(setSubscribeSuccess, () => defaultNewSubscription)
  .case(resetState, () => defaultNewSubscription)
  .build();
