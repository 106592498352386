import { Box, MenuItem, Select, Typography, useTheme } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ccIcon from '../assets/icons/CreditCard.svg';
import paypalIcon from '../assets/icons/PayPal.svg';
import sepaIcon from '../assets/icons/SEPA.svg';
import { CurrentPaymentMethodDescription } from '../common/CurrentPaymentMethodDescription';
import { Payment } from './state';
import { usePayment } from './usePayment';

export interface PaymentMethodSelectProps {
  onlyDefault?: boolean;
}

export const PaymentMethodSelect: React.FC<PaymentMethodSelectProps> = ({ onlyDefault }) => {
  const {
    setSelectedPaymentType,
    payment: {
      defaultPaymentMethod,
      selectedPaymentMethodType,
      paymentCurrency,
      paymentCountry,
      disableSelectPaymentTypeCount,
    },
  } = usePayment();

  const { t } = useTranslation();

  useEffect(() => {
    if (onlyDefault && defaultPaymentMethod) {
      setSelectedPaymentType('default');
    }
  }, [defaultPaymentMethod, onlyDefault, setSelectedPaymentType]);

  if (onlyDefault && defaultPaymentMethod) {
    return (
      <SelectContainer>
        <Typography variant='h3'>{t('Your Payment Method')}</Typography>
        <CurrentPaymentMethodDescription paymentMethod={defaultPaymentMethod} />
      </SelectContainer>
    );
  }

  if (onlyDefault && !defaultPaymentMethod) {
    return (
      <SelectContainer>
        <Typography variant='h3'>{t('Your Payment Method')}</Typography>
        <Typography>{t('No current Method')}</Typography>
      </SelectContainer>
    );
  }
  return (
    <SelectContainer>
      <Typography variant='h3' id='choosePaymentMethodTitle'>
        {t('Choose Payment Method')}
      </Typography>
      <Select
        fullWidth
        id='payment-method-select'
        disabled={disableSelectPaymentTypeCount !== 0}
        value={selectedPaymentMethodType}
        style={selectStyle}
        onChange={(event) => {
          setSelectedPaymentType(event.target.value as Payment['selectedPaymentMethodType']);
        }}
        IconComponent={SelectIcon}
        defaultValue='PayPal'
        MenuProps={{ disableScrollLock: true }}
      >
        {!!defaultPaymentMethod && (
          <MenuItem value={'default'}>
            <CurrentPaymentMethodDescription paymentMethod={defaultPaymentMethod} />
          </MenuItem>
        )}

        <MenuItem value={'CreditCard'}>
          <Box style={selectItemStyle}>
            <img src={ccIcon} alt='' style={selectItemIconStyle} /> {t('Credit Card')}
          </Box>
        </MenuItem>

        <MenuItem value={'PayPal'}>
          <Box style={selectItemStyle}>
            <img src={paypalIcon} alt='' style={selectItemIconStyle} /> {t('PayPal')}
          </Box>
        </MenuItem>

        {paymentCurrency === 'USD' && paymentCountry === 'US' && (
          <MenuItem value={'ACH'}>
            <Box style={selectItemStyle}>
              <img src={sepaIcon} alt='' style={selectItemIconStyle} /> {t('ACH')}
            </Box>
          </MenuItem>
        )}

        {(paymentCurrency === 'EUR' || paymentCurrency === 'GBP') && (
          <MenuItem value={'SEPA'}>
            <Box style={selectItemStyle}>
              <img src={sepaIcon} alt='' style={selectItemIconStyle} /> {t('SEPA')}
            </Box>
          </MenuItem>
        )}
      </Select>
    </SelectContainer>
  );
};

const SelectIcon: React.FC<React.ComponentProps<typeof ExpandMore>> = (props) => (
  <ExpandMore color='primary' {...props} />
);

const selectStyle = {
  margin: '16px 0 24px',
};

const selectItemStyle = {
  display: 'inline-flex',
  alignItems: 'center',
};

const selectItemIconStyle = {
  marginRight: '8px',
  width: '29px',
  height: '29px',
};

const SelectContainer: React.FC = (props) => {
  const theme = useTheme();
  return <Box padding={theme.spacing(1, 0)} {...props} />;
};
