import { Reducer } from 'react';
import { ActionCreator } from 'typescript-fsa';
import { Action } from 'typescript-fsa/src';

type Handler<State, Payload> = (state: State, payload: Payload) => State;

export class ReducerBuilder<State> {
  private cases: Map<string, Handler<State, any>> = new Map();

  case<T>(actionCreator: ActionCreator<T>, handler: Handler<State, T>) {
    if (this.cases.has(actionCreator.type)) {
      throw new Error(`case for ${actionCreator.type} was already set`);
    }
    this.cases.set(actionCreator.type, handler);
    return this;
  }

  build(): Reducer<State, Action<unknown>> {
    return (state, action) => {
      const caseEntry = this.cases.get(action.type);
      if (caseEntry) {
        return caseEntry(state, action.payload);
      }
      return state;
    };
  }
}
