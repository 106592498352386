import { sessionId } from '../session/makeSessionId';
import { baseURL } from './baseUrl';

class FetchClient {
  constructor(private bearerToken?: string) {}

  postJson<T>(apiUrl: string | URL, payload: unknown, init?: RequestInit) {
    const url = new URL(apiUrl, baseURL);
    const req = new Request(url.toString(), {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return this.fetchJson<T>(req, init);
  }

  getJson<T>(apiUrl: string | URL, init?: RequestInit): Promise<T> {
    const url = new URL(apiUrl, baseURL);
    return this.fetchJson(url.toString(), init);
  }

  fetchJson<T>(...[input, init]: Parameters<typeof fetch>): Promise<T> {
    return fetch(input, this.initRequest(init)).then((response) => {
      return response.ok ? response.json() : Promise.reject(response);
    });
  }

  private initRequest(init?: RequestInit): RequestInit {
    return {
      ...init,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        ...init?.headers,
        ...(this.bearerToken && { Authorization: `Bearer ${this.bearerToken}` }),
        'X-SESSION-ID': sessionId,
      },
    };
  }
}

export const getFetch = (bearerToken?: string) => {
  return new FetchClient(bearerToken);
};
