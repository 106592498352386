import { Grid, withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const SkeletonWithText = withStyles({
  grid: {
    height: '100%',
  },
  withChildren: {
    '& > *': {
      visibility: 'visible',
      textAlign: 'center',
    },
  },
})(({ loadingText, classes, ...props }) => (
  <Skeleton {...props} classes={{ withChildren: classes.withChildren }}>
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      classes={{ root: classes.grid }}
    >
      <Grid item xs={12}>
        {loadingText}
      </Grid>
    </Grid>
  </Skeleton>
));
