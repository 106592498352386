import { useMemo } from 'react';
import { Config } from './types';

export const useConfig = (): Config =>
  useMemo(
    () => ({
      PUBLIC_URL: process.env.PUBLIC_URL ?? '',
      ENVIRONMENT: (process.env.FE_ENVIRONMENT as any) ?? 'development',
      zuora: {
        host: process.env.FE_ZUORA_ENVIRONMENT_HOST ?? '',
        hpm: {
          ach: process.env.FE_ZUORA_HPM_ACH ?? '',
          creditCard: process.env.FE_ZUORA_HPM_CREDITCARD ?? '',
          creditCardUS: process.env.FE_ZUORA_HPM_CREDITCARD_US ?? '',
          sepa: process.env.FE_ZUORA_HPM_SEPA ?? '',
        },
      },
      here: {
        key: process.env.FE_HERE_API_KEY ?? '',
      },
      sentry: {
        dsn: process.env.FE_SENTRY_DSN ?? ((undefined as unknown) as string), // 104867 TODO add the key for tests
      },
      authentication: {
        accountPortal:
          process.env.FE_AUTHENTICATION_ACCOUNT_PORTAL ?? 'https://dev.accounts.enscape3d.com',
        frontendSsoGateway:
          process.env.FE_AUTHENTICATION_SSO_GATEWAY ?? 'https://dev.ssogateway.enscape3d.com',
      },
    }),
    [],
  );
