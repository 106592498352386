import { Theme } from '@material-ui/core';
import { AlertClassKey, AlertTitleClassKey } from '@material-ui/lab';

export const muiAlertOverrides = (theme: Theme): Theme['overrides'] => ({
  MuiAlert: {
    root: {
      borderRadius: 0,
      fontSize: 14,
    },
    icon: {
      marginTop: theme.spacing(1),
    },
  },
  MuiAlertTitle: {
    root: {
      fontWeight: 'bold',
    },
  },
});

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAlertTitle: AlertTitleClassKey;
  }
}
