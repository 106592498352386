import { Event } from '@sentry/browser';

export const eventFromResponse = (event: Event, response: Response): Event => {
  const value = `Request to (${response.url}) failed with status ${response.status}`;
  event.exception = { values: [{ type: 'UnhandledFetchError', value }] };

  const tags = event.tags || {};
  tags['xhrUrl'] = response.url;
  tags['xhrStatus'] = response.status;
  event.tags = tags;

  event.fingerprint = [response.url, response.status.toString()];
  return event;
};
