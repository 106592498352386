// eslint-disable-next-line no-restricted-imports
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import {
  infoFeedback,
  negativeFeedback,
  positiveFeedback,
  primaryPurple,
  secondaryEnscapeOrange,
  warningFeedback,
} from '../colors';

export const palette: PaletteOptions = {
  common: {
    white: '#FFFFFF',
  },
  primary: {
    main: secondaryEnscapeOrange,
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: primaryPurple,
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#333333',
  },
  error: {
    main: negativeFeedback,
  },
  success: {
    main: positiveFeedback,
  },
  info: {
    main: infoFeedback,
  },
  warning: {
    main: warningFeedback,
  },
};
