import qs from 'qs';
import api from './api';

import apiEndpoints from './apiEndpoints';
import { getStateFromAddress } from './getStateFromAddress';

interface AddressVerificationDetails {
  address: string;
  zip: string;
  city: string;
  country: string;
}

const isAddressDataValid = async ({
  address,
  zip,
  city,
  state,
  country,
}: AddressVerificationDetails & { state: string }) => {
  const requestPayload = qs.stringify({
    zip,
    city,
    state,
    country,
    address1: address,
  });
  try {
    const { data } = await api.post<{ isAddressVerified: boolean; reason: string }>(
      apiEndpoints.address,
      requestPayload,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    return data;
  } catch (e) {
    console.error(e);
  }
  return { isAddressVerified: false, reason: 'verification failure' };
};

export const validateAddress = async (addressDetails: AddressVerificationDetails) => {
  const state = await getStateFromAddress(addressDetails.zip, addressDetails.country);
  if (!state) {
    return { isAddressVerified: false, reason: 'Could not find state' };
  }
  const response = await isAddressDataValid({ ...addressDetails, state });
  return { ...response, state };
};
