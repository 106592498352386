import {
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  styled,
  Theme as DefaultTheme,
  Typography,
} from '@material-ui/core';
import { Remove } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useRef, useState } from 'react';
import useMediaQueries from '../context/MediaQueries';
import Paper from './Paper';

export const CardCloseButton = styled(IconButton)({
  '#root &': {
    borderRadius: 0,
    background: 'white',
    fontSize: 24,
    width: '100%',
    padding: 0,
    boxShadow: 'none',
    height: '30px',
  },
});

CardCloseButton.displayName = 'CloseButton';

export const CardSmallTypography = styled(Typography)({
  '#root &': {
    ontSize: '13px',
  },
});

export const CardBoldTypography = styled(Typography)({
  '#root &': {
    fontWeight: 'bold',
  },
});

export const CardEnscapeDivider = styled(Divider)({
  '#root &': {
    background: 'orange',
  },
});

export const CardCustomRemove = styled(Remove)({
  '#root &': {
    alignSelf: 'center',
    color: 'orange',
  },
});

export const CardHeader = styled(CardContent)({
  '#root &': {
    padding: '0 16',
  },
});

const buttonHeight = 34;
export const navbarHeight = 60;

const getContentHeight = (contentRef: React.RefObject<HTMLDivElement>, open: boolean) => {
  if (!contentRef.current || open) {
    return '-' + buttonHeight + 'px';
  }
  const totalHeight = contentRef.current.getBoundingClientRect().height;
  const contentHeight = totalHeight - buttonHeight + navbarHeight;
  return '-' + contentHeight + 'px';
};

const useStyles = makeStyles<DefaultTheme, { offset?: string }>({
  wrapper: {
    borderRadius: 0,
    background: 'white',
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    position: 'absolute',
    maxWidth: '100%',
    transition: 'all 350ms ease-out',
    zIndex: 100,
    marginTop: navbarHeight,
    bottom: ({ offset }) => offset,
  },
  border: {
    border: '1px solid #C4C4C4',
  },
  headerContentMargin: {
    margin: '0 0 0 16px',
  },
  gridLayout: {
    order: 2,
    padding: 16,
  },
  closeButton: {
    zIndex: 200,
  },
  relative: {
    position: 'relative',
    marginBottom: 30,
  },
  alignEnd: {
    textAlign: 'end',
  },
});

export const CardMobileLayout: React.FC = ({ children, ...rest }) => {
  const content = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({ open: false, offset: getContentHeight(content, false) });

  const handleClick = () => {
    setState(({ open }) => ({ open: !open, offset: getContentHeight(content, open) }));
  };

  const classes = useStyles(state);

  return (
    <Grid item xs={12} className={classes.relative} {...rest}>
      <div className={classes.wrapper} ref={content}>
        {children}
        <CardCloseButton className={classes.closeButton} onClick={handleClick}>
          <CardCustomRemove />
        </CardCloseButton>
      </div>
    </Grid>
  );
};

export const CardDesktopLayout: React.FC = ({ children, ...rest }) => {
  const classes = useStyles({});
  return (
    <Grid className={classes.gridLayout} item xs={6} {...rest}>
      <Paper variant={'outlined'} className={classes.border}>
        {children}
      </Paper>
    </Grid>
  );
};

export const PreviewAnimation: React.FC = () => (
  <Skeleton variant='text' animation='wave' className='preview' aria-label={'preview-animation'} />
);

export const CardWrapper: React.FC<React.ComponentProps<typeof Grid>> = (props) => {
  const { desktop } = useMediaQueries();
  const Layout = desktop ? CardDesktopLayout : CardMobileLayout;

  return <Layout {...props} />;
};
