import { Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useUserSettings } from '../userSettings';
import { usePortalHrefs } from './usePortalHrefs';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    textAlign: 'left',
    color: theme.palette.secondary.main,
    float: 'right',
    '&:hover, &:active': {
      textDecoration: 'underline',
      // @ts-ignore
      color: theme.palette.primary.hover,
    },
  },
}));

export const Logout: React.FC = () => {
  const styles = useStyles();
  const { logout } = usePortalHrefs();
  const {
    userSettings: { isLoggedIn },
  } = useUserSettings();
  return isLoggedIn ? (
    <Link href={logout} className={styles.link}>
      <Typography>Log out</Typography>
    </Link>
  ) : null;
};
