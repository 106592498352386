import { useWindowEvent } from '../utils/useWindowEvent';
import { UserSettingsEvents } from './events';
import { useUserSettings } from './useUserSettings';

export const useChangeCurrencyEvent = () => {
  const { setDefaultCurrency } = useUserSettings();
  useWindowEvent(UserSettingsEvents.ChangeCurrency, (e) => {
    setDefaultCurrency(e.detail);
  });
};
