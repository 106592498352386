import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { postVerifyCookie } from '../api';
import { CircularProgress } from '../common/CircularProgress';
import { useContact } from '../contact/useContact';
import { cartPaths } from '../navigation/UrlPaths';
import { useUserSettings } from '../userSettings';
import EmailForm from './EmailForm';
import { LoginFormViewState } from './types';

const Login: React.FC = () => {
  const { setZuoraAccount, setContact } = useContact();
  const { userSettings, setUserIsLoggedIn } = useUserSettings();
  const history = useHistory();
  const [viewState, setViewState] = useState<LoginFormViewState>('NEW_USER');

  useEffect(() => {
    const verifyCookie = async () => {
      try {
        setViewState('PENDING');
        const zuoraAccount = await postVerifyCookie();
        switch (zuoraAccount.code) {
          case 'INVALID':
            setViewState('NEW_USER');
            break;
          case 'USER':
            setZuoraAccount({ zAcc: zuoraAccount });
            setViewState('KNOWN_USER');
            history.push(cartPaths.PAYMENT);
            break;
          case 'USER_IS_RESELLER':
            window.location.href = 'https://my.enscape3d.com/account#!/login';
            break;
          case 'NO_PAYMENT_ACCOUNT':
            setContact({ currency: userSettings.defaultCurrency });
            setUserIsLoggedIn();
            history.push(cartPaths.BILLING);
            break;
        }
      } catch (error) {
        //no cookie found
        setViewState('NEW_USER');
      }
    };
    verifyCookie();
  }, [setZuoraAccount, history, setContact, userSettings.defaultCurrency, setUserIsLoggedIn]);

  if (viewState === 'PENDING') {
    return (
      <div style={spinnerStyle}>
        <CircularProgress size={50} />
      </div>
    );
  }
  return <EmailForm />;
};

const spinnerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 400,
};

export default Login;
