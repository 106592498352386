import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import {
  getCatalog,
  GetCatalogResponse,
  getLocalization,
  getPaymentMethod,
  postPayBill,
} from '../api';
import { dispatchWebshopMessage } from '../webshopMessages';

export const useGetPaymentMethod = (paymentMethodId?: string) =>
  useQuery({
    queryKey: ['getPaymentMethod', paymentMethodId],
    queryFn: () => getPaymentMethod(paymentMethodId),
    enabled: Boolean(paymentMethodId),
  });

export const useGetLocalization = () =>
  useQuery({
    queryKey: ['localization'],
    queryFn: getLocalization,
  });

const oneHour = 3_600_000;

export const useGetCatalog = (
  promoCode: string | null,
  options?: UseQueryOptions<GetCatalogResponse>,
) =>
  useQuery({
    queryKey: ['catalog', promoCode],
    staleTime: oneHour,
    queryFn: () => getCatalog(promoCode),
    ...options,
  });

export const usePostPayBill = () =>
  useMutation(postPayBill, { onSuccess: () => void dispatchWebshopMessage({ type: 'BillsPaid' }) });
