import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AmexLogo from '../assets/icons/amex.svg';
import VisaLogo from '../assets/icons/Logo_Visa.svg';
import MastercardLogo from '../assets/icons/Mastercard-logo.svg';
import { usePayment } from '../payment/usePayment';

const CreditCardDescription: React.FC = () => (
  <Grid container direction='row' justifyContent='space-evenly' alignItems='center' wrap='nowrap'>
    <Grid item style={{ textAlign: 'center' }}>
      <img src={VisaLogo} alt='VisaLogo' />
    </Grid>
    <Grid item style={{ textAlign: 'center' }}>
      <img src={MastercardLogo} alt='MastercardLogo' />
    </Grid>
    <Grid item style={{ textAlign: 'center' }}>
      <img src={AmexLogo} alt='AmexLogo' />
    </Grid>
  </Grid>
);

export const NewPaymentMethodDescription: React.FC = () => {
  const { t } = useTranslation();
  const {
    payment: { selectedPaymentMethodType },
  } = usePayment();
  switch (selectedPaymentMethodType) {
    case 'PayPal':
      return <Typography>{t('You will be redirected to PayPal to confirm')}</Typography>;
    case 'CreditCard':
      return <CreditCardDescription />;
  }
  return null;
};
