import qs from 'qs';
import { Subscription } from '../subscription';
import api from './api';
import apiEndpoints from './apiEndpoints';
import { getFetch } from './fetchClient';
import {
  AccountPayload,
  AuthenticationResponse,
  BillPaymentResponse,
  GetCatalogResponse,
  LocalizationResponse,
  PaymentMethod,
  PostSubscriptionResponse,
  PreviewResponse,
  SubscriptionPayloadSchema,
  VATValidationResponse,
  ZuoraResponse,
} from './types';

export const postPayBill = ({
  invoiceIds,
  debitMemoIds,
  bearerToken,
}: {
  invoiceIds: string[];
  debitMemoIds: string[];
  bearerToken: string;
}): Promise<BillPaymentResponse> =>
  getFetch(bearerToken).postJson('/api/payment/billing', {
    invoiceIds,
    debitMemoIds,
  });

export const getCatalog = (promoCode: string | null) =>
  api
    .get<GetCatalogResponse>('/api/catalog', {
      params: {
        promoCode,
        rateplanId: ['EN00MPANSCK001', 'EN00MPMOSCI001', 'EN00MPANSCI001'],
      },
      paramsSerializer: (param) => qs.stringify(param, { arrayFormat: 'repeat' }),
    })
    .then((x) => x.data);

export const getLocalization = (): Promise<LocalizationResponse> =>
  getFetch().getJson('/api/localization');

export const getPaymentMethod = async (paymentMethodId?: string) =>
  (await api.get<PaymentMethod>(apiEndpoints.paymentMethod + paymentMethodId)).data;

export const getVerifyVatId = (vatId: string) => {
  const url = apiEndpoints.vat + encodeURIComponent(vatId);
  return api.get<VATValidationResponse>(url);
};

export const postVerifyCookie = () =>
  api.post<AuthenticationResponse>(apiEndpoints.verifyCookie).then((response) => response.data);

export const postPayPalBaid = (token: string) =>
  api
    .post<{ email: string; baid: string }>('/api/paypal/baid', { token })
    .then((response) => response.data);

export const postPreview = ({
  currency,
  ratePlans,
  promoCode,
  ...contact
}: {
  currency: string;
  promoCode?: string;
  city: string;
  country: string;
  zipCode: string;
  state?: string;
  vatId?: string;
  ratePlans: Array<{
    ratePlanId: string;
    seats: number;
  }>;
}) => {
  const subscribeParams = {
    promoCode,
    contact,
    account: {
      currency,
    },
    subscription: {
      ratePlans,
    },
  };
  return api
    .post<ZuoraResponse<PreviewResponse>>(apiEndpoints.getPreview, subscribeParams)
    .then((response) => response.data);
};

export const postSubscribeCreate = (
  account: AccountPayload,
  paymentMethod: SubscriptionPayloadSchema['paymentMethod'],
  subscriptions: Subscription[],
  hasPaymentAccount: boolean,
  promoCode?: string,
) => {
  const adaptedPayload: SubscriptionPayloadSchema = {
    ...{ promoCode },
    paymentMethod,
    products: subscriptions.map((s) => ({ productRatePlanId: s.ratePlanId, quantity: s.seats })),
  };

  return api
    .post<PostSubscriptionResponse>(
      apiEndpoints.subscriptionCreate,
      hasPaymentAccount
        ? { ...adaptedPayload, updateAccount: account }
        : { ...adaptedPayload, newAccount: account },
    )
    .then((response) => response.data);
};

export const postSubscription = (
  payload: {
    quantity: number;
    ratePlanId: string;
    promoCode?: string;
  },
  bearerToken?: string,
) => getFetch(bearerToken).postJson<PostSubscriptionResponse>('/api/subscription', payload);
