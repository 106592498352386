import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import type { ClassNameMap } from '@material-ui/styles/withStyles';
import { History } from 'history';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { EnscapeLogo } from '../common/EnscapeLogo';
import {
  NavigationBackButtonIcon,
  NavigationBar,
  NavigationCloseButtonIcon,
  NavigationTitle,
  NavigationWhiteCircle,
} from '../common/Navigation';
import useMediaQueries from '../context/MediaQueries';
import { cartPaths, paths } from './UrlPaths';

const stepCount = 3;

const WebshopNavigationBar: React.FC<{ mainHistory: History }> = ({ mainHistory }) => {
  const history = useHistory();
  const refCircle1 = useRef<HTMLDivElement>(null);
  const refBilling = useRef<HTMLDivElement>(null);
  const refCircle2 = useRef<HTMLDivElement>(null);
  const refPayment = useRef<HTMLDivElement>(null);
  const refCompleted = useRef<HTMLDivElement>(null);
  const refDownload = useRef<HTMLDivElement>(null);
  const refInnerWrapper = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { mobile } = useMediaQueries();
  const classes = useNavigationStyles({ pathname, mobile });

  useEffect(() => {
    switch (pathname) {
      case cartPaths.LOGIN:
        refCircle1.current?.scrollIntoView();
        refInnerWrapper.current?.scrollTo({ left: 0, behavior: 'smooth' });
        break;
      case cartPaths.BILLING:
        refCircle1.current?.scrollIntoView();
        refInnerWrapper.current?.scrollTo({ left: 0, behavior: 'smooth' });
        break;
      case cartPaths.PAYMENT:
        refCircle1.current?.scrollIntoView();
        refInnerWrapper.current?.scrollTo({
          left: refBilling.current?.getBoundingClientRect().width,
          behavior: 'smooth',
        });
        break;
      case cartPaths.SUCCESS:
        refCompleted.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case cartPaths.DOWNLOAD:
        refDownload.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  }, [pathname]);

  const toBilling = () => {
    if (pathname === cartPaths.PAYMENT) {
      history.push(cartPaths.BILLING);
    }
  };

  return (
    <>
      <NavigationBar>
        <Grid container spacing={0}>
          <Grid item xs={1} className={classes.backButton}>
            <BackButton pathname={pathname} mainHistory={mainHistory} classes={classes} />
          </Grid>
          <Grid item xs={11} className={classes.relative}>
            <Box className={classes.sliderWrapper}>
              <Box className={classes.topLevelSlider}>
                <Box className={classes.topLevelStep}>
                  <Box className={classes.secondLevelStep}>
                    <NavigationWhiteCircle
                      className={classes.marginOneIcon}
                      ref={refCircle1}
                      onClick={toBilling}
                    >
                      1
                    </NavigationWhiteCircle>
                    <Box className={classes.secondLevelSlider} {...{ ref: refInnerWrapper }}>
                      <Box className={`${classes.secondLevelStep} ${classes.width200}`}>
                        <NavigationTitle
                          ref={refBilling}
                          className={classes.paddingRight}
                          id='drawertitle1'
                        >
                          {t('Billing Information')}
                        </NavigationTitle>
                        <Box className={classes.fade}>
                          <NavigationWhiteCircle className={classes.marginTwoIcon} ref={refCircle2}>
                            2
                          </NavigationWhiteCircle>
                          <NavigationTitle
                            className={`${classes.fade} ${classes.hideOnMobile1}`}
                            ref={refPayment}
                            id='drawertitle2'
                          >
                            {t('Payment')}
                          </NavigationTitle>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.topLevelStep} {...{ ref: refCompleted }}>
                  <NavigationTitle id='drawertitle3'>{t('Purchase Completed')}</NavigationTitle>
                </Box>
                <Box className={classes.topLevelStep} {...{ ref: refDownload }}>
                  <NavigationTitle>{t('Download')}</NavigationTitle>
                </Box>
              </Box>
            </Box>
            <EnscapeLogo className={classes.logoSlide} />
          </Grid>
        </Grid>
      </NavigationBar>
    </>
  );
};

export const BackButton: React.FC<{
  pathname: string;
  mainHistory: History;
  classes: ClassNameMap<'backButtonIcon' | 'clearButtonIcon'>;
}> = ({ pathname, mainHistory }) => {
  if (pathname === cartPaths.SUCCESS || pathname === cartPaths.DOWNLOAD) {
    return (
      <NavigationCloseButtonIcon
        onClick={() => mainHistory.push(paths.PRICING)}
        aria-label='back'
      />
    );
  }
  return (
    <NavigationBackButtonIcon onClick={() => mainHistory.push(paths.PRICING)} aria-label='back' />
  );
};

export const useNavigationStyles = makeStyles<Theme, { mobile?: boolean; pathname: string }>({
  backButton: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  },
  backButtonMarginLeft: {
    position: 'relative',
    margin: '0 0 0 15px',
  },
  backButtonIcon: {
    cursor: 'pointer',
    fontSize: 40,
  },
  sliderWrapper: {
    overflowX: 'hidden',
    position: 'absolute',
    top: '50%',
    left: ({ mobile }) => (!mobile ? '' : '10px'),
    transform: 'translate(0, -50%)',
    width: 'inherit',
  },
  topLevelSlider: {
    width: `calc(100% * ${stepCount})`,
  },
  topLevelStep: {
    display: 'inline-flex',
    width: `calc(100% / ${stepCount})`,
    whiteSpace: 'nowrap',
  },
  secondLevelSlider: {
    overflowX: 'hidden',
    width: '200%',
  },
  secondLevelStep: {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  fade: {
    opacity: ({ pathname }) =>
      pathname === cartPaths.LOGIN || pathname === cartPaths.BILLING ? 0.7 : 1,
    transition: 'opacity 500ms ease-in-out',
    display: 'inline-flex',
  },
  hideOnMobile1: {
    display: ({ mobile, pathname }) =>
      mobile && (pathname === cartPaths.LOGIN || pathname === cartPaths.BILLING)
        ? 'none'
        : 'inherit',
  },
  paddingRight: {
    paddingRight: '20px',
  },
  downloadLogo: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  logoSlide: {
    display: ({ pathname }) => (pathname === cartPaths.DOWNLOAD ? 'none' : 'inherit'),
    position: 'absolute',
    margin: 'auto',
    right: 0,
    top: 0,
    bottom: 0,
    paddingRight: '10px',
  },
  relative: {
    position: 'relative',
  },
  marginOneIcon: {
    marginLeft: '10px',
  },
  marginTwoIcon: {
    marginLeft: '10px',
  },
  marginLeft: {
    marginLeft: '25px',
  },
  width200: {
    width: '200%',
  },
});

export default WebshopNavigationBar;
