import { countries } from '../billing/CountryCodes';
import { externalEndpoints } from './externalEndpoints';

export interface GeocodeResponse {
  items: Array<{ address?: { postalCode: string; stateCode: string } }>;
}

export const getStateFromAddress = async (zipCode: string, country: string) => {
  const url = new URL(externalEndpoints.geocode);
  url.searchParams.append('apiKey', process.env.FE_HERE_API_KEY);
  url.searchParams.append(
    'qq',
    'postalCode=' +
      encodeURIComponent(zipCode) +
      ';country=' +
      countries.find((c) => c.code === country)?.codeISO3,
  );

  const response = await fetch(url.toString());

  if (!response?.ok) {
    return false;
  }
  const geocodeResponse = (await response.json()) as GeocodeResponse;

  if (!geocodeResponse.items.length) {
    return false;
  }

  const computedZipCode = geocodeResponse.items[0].address?.postalCode;
  const zipCodeMatches = computedZipCode === zipCode;
  const zipCodeMatchesCanada = computedZipCode === zipCode.toUpperCase().substring(0, 3);
  if (zipCodeMatches || zipCodeMatchesCanada) {
    return geocodeResponse.items[0].address?.stateCode;
  } else {
    return false;
  }
};

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      FE_HERE_API_KEY: string;
    }
  }
}
