import { Event } from '@sentry/types/dist/event';
import { eventFromResponse } from './eventFromResponse';

export const beforeSend = async (event: Event): Promise<Event | null> => {
  const message = event.message as any;
  switch (true) {
    // happens when response from Fetch is thrown and not handled
    case message instanceof Response: {
      return eventFromResponse(event, message);
    }
    // errors from recaptcha and other plugins
    case event.exception?.values?.[0]?.value?.includes(
      'Non-Error promise rejection captured with value',
    ): {
      return null;
    }
  }
  return event;
};
