import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useCurrency } from '../context/getCurrency';
import { currencies, Currency } from '../currency';
import { useUserSettings } from '../userSettings';

const CurrencySwitch: React.FC = () => {
  const currency = useCurrency();
  const { setDefaultCurrency } = useUserSettings();

  return (
    <Select
      aria-label='switch currency'
      variant='standard'
      value={currency}
      onChange={(event) => {
        setDefaultCurrency(event.target.value as Currency);
      }}
      MenuProps={{ disableScrollLock: true }}
    >
      {currencies.map((value) => {
        return (
          <MenuItem value={value} key={value}>
            {value}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CurrencySwitch;
