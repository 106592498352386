import { Drawer, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { ClosedSidebarLink } from './ClosedSidebarLink';
import { useDrawerPaperStyles } from './useDrawerPaperStyles';

export interface RightDrawerProps {
  open: boolean;
  closedSidebar?: JSX.Element;
  isFoldedDrawerShown?: boolean;
}

export const RightDrawer: React.FC<RightDrawerProps> = ({
  open,
  closedSidebar = <ClosedSidebarLink />,
  isFoldedDrawerShown,
  children,
}) => {
  const hideFoldedDrawerRef = useRef(true);
  const scrollbarRef = useRef(window.innerWidth - document.body.offsetWidth);

  if (!isFoldedDrawerShown) {
    hideFoldedDrawerRef.current = true;
  }

  if (open || isFoldedDrawerShown) {
    hideFoldedDrawerRef.current = false;
  }

  useEffect(() => {
    const htmlElement = document.querySelector('html');

    if (!htmlElement) {
      return;
    }

    if (document.body.clientHeight < window.innerHeight) {
      return;
    }
    if (open) {
      htmlElement.style.overflow = 'hidden';
      htmlElement.style.marginRight = scrollbarRef.current + 'px';
    }
    if (!open) {
      htmlElement.style.overflow = 'unset';
      htmlElement.style.marginRight = '0';
    }
  }, [open]);

  const drawerStyles = useDrawerStyles();
  const drawerPaperStyles = useDrawerPaperStyles({
    open,
    hideFoldedDrawer: hideFoldedDrawerRef.current,
  });

  return (
    <Drawer
      id='rightdrawer'
      className={drawerStyles.drawer}
      variant='permanent'
      anchor='right'
      open={open}
      transitionDuration={1}
      PaperProps={{ classes: { root: drawerPaperStyles.drawerPaper } }}
    >
      {open ? <>{children}</> : !hideFoldedDrawerRef.current && closedSidebar}
    </Drawer>
  );
};

const useDrawerStyles = makeStyles({
  drawer: {
    overflow: 'hidden',
  },
});
