import { requestStatus } from '../common/requestStatus';
import { ReducerBuilder } from '../context/reducerBuilder';
import { setPreview, setPreviewStatus } from './actions';
import { Preview } from './state';

export const previewReducer = new ReducerBuilder<Preview>()
  .case(setPreview, (state, payload) => ({
    value: payload.preview,
    status: requestStatus.VERIFIED,
  }))
  .case(setPreviewStatus, (state, payload) => ({
    ...state,
    status: payload.status,
  }))
  .build();
