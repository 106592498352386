export interface Account {
  accountId: string;
  accountNumber: string;
}

export interface AccountState {
  account: Account;
}

export const defaultAccount: Account = {
  accountNumber: '',
  accountId: '',
};
