import actionCreatorFactory from 'typescript-fsa';
import { requestStatus } from '../common/requestStatus';
import { PreviewState } from './state';

export const actionCreator = actionCreatorFactory('PREVIEW');

export const setPreview = actionCreator<{ preview: PreviewState['preview']['value'] }>(
  'SET_PREVIEW',
);

export const setPreviewStatus = actionCreator<{
  status: typeof requestStatus[keyof typeof requestStatus];
}>('SET_PREVIEW_STATUS');
