import { setZuoraAccount } from '../contact';
import { ReducerBuilder } from '../context/reducerBuilder';
import { resetState } from '../context/resetState';
import {
  setDefaultMethodId,
  setDefaultPaymentMethod,
  setDisablePayment,
  setDisableSelectPaymentType,
  setEnablePayment,
  setEnableSelectPaymentType,
  setPaymentAmount,
  setPaymentCountry,
  setPaymentCurrency,
  setPaymentError,
  setPaymentPageIsLoading,
  setPaymentSuccess,
  setSelectedPaymentType,
  setShowSpinner,
  setUsedPaymentType,
  setUserAgreementRequired,
  setUserPrefillData,
  startPayment,
  toggleUserAgreementAccepted,
} from './actions';
import { defaultPayment, Payment } from './state';

export const paymentReducer = new ReducerBuilder<Payment>()
  .case(setDefaultMethodId, (state, payload) => ({
    ...state,
    defaultPaymentMethodId: payload,
  }))
  .case(setUsedPaymentType, (state, payload) => ({
    ...state,
    usedPaymentType: payload,
  }))
  .case(toggleUserAgreementAccepted, (state) => ({
    ...state,
    userAgreementAccepted: !state.userAgreementAccepted,
  }))
  .case(setShowSpinner, (state, payload) => ({
    ...state,
    showSpinner: payload,
  }))
  .case(setSelectedPaymentType, (state, payload) => ({
    ...state,
    selectedPaymentMethodType: payload,
  }))
  .case(startPayment, (state) => ({
    ...state,
    process: 'started',
  }))
  .case(setPaymentSuccess, ({ errorMessage, ...state }, payload) => ({
    ...state,
    process: 'success',
    paymentMethodSuccess: payload,
  }))
  .case(setPaymentError, ({ paymentMethodSuccess, ...state }, payload) => ({
    ...state,
    process: 'error',
    errorMessage: payload,
  }))
  .case(setDefaultPaymentMethod, (state, payload) => ({
    ...state,
    defaultPaymentMethod: payload,
  }))
  .case(setPaymentPageIsLoading, (state, payload) => ({
    ...state,
    paymentPageIsLoading: payload,
  }))
  .case(setUserAgreementRequired, (state, payload) => ({
    ...state,
    userAgreementRequired: payload,
  }))
  .case(setDisablePayment, ({ disablePaymentCount, ...state }) => ({
    ...state,
    disablePaymentCount: disablePaymentCount + 1,
  }))
  .case(setEnablePayment, ({ disablePaymentCount, ...state }) => ({
    ...state,
    disablePaymentCount: disablePaymentCount === 0 ? 0 : disablePaymentCount - 1,
  }))
  .case(setPaymentAmount, (state, paymentAmount) => ({
    ...state,
    paymentAmount,
  }))
  .case(setPaymentCurrency, (state, paymentCurrency) => ({
    ...state,
    paymentCurrency,
  }))
  .case(setPaymentCountry, (state, paymentCountry) => ({
    ...state,
    paymentCountry,
  }))
  .case(setDisableSelectPaymentType, ({ disableSelectPaymentTypeCount, ...state }) => ({
    ...state,
    disableSelectPaymentTypeCount: disableSelectPaymentTypeCount + 1,
  }))
  .case(setEnableSelectPaymentType, ({ disableSelectPaymentTypeCount, ...state }) => ({
    ...state,
    disableSelectPaymentTypeCount:
      disableSelectPaymentTypeCount === 0 ? 0 : disableSelectPaymentTypeCount - 1,
  }))
  .case(setUserPrefillData, (state, userPrefillData) => ({
    ...state,
    userPrefillData,
  }))
  .case(resetState, () => defaultPayment)
  .case(setZuoraAccount, (state, { zAcc: { paymentInformation } }) => ({
    ...state,
    defaultPaymentMethodId: paymentInformation.DefaultPaymentMethodId,
  }))
  .build();
