import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const languages = ['en', 'de', 'fr', 'it', 'es', 'pt', 'ja'];

export const LanguageSelect: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <div>
      <Select
        aria-label='switch language'
        variant='standard'
        value={i18n.language}
        onChange={(event) => {
          i18n.changeLanguage(event.target.value as string);
        }}
        MenuProps={{ disableScrollLock: true }}
      >
        {languages.map((value) => {
          return (
            <MenuItem value={value} key={value}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};
