import { TFunction } from 'i18next';

export const getHelperText = (
  error: string | undefined,
  inputIsLatinCheck: boolean,
  t: TFunction,
) => {
  const errors: string[] = [];

  if (error === 'The address number is out of range') {
    errors.push(t('Could not verify address number'));
  } else if (error) {
    errors.push(t('Address could not be verified'));
  }

  if (!inputIsLatinCheck) {
    errors.push(t('Only Latin letters allowed.'));
  }
  return errors;
};
