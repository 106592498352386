import { defaultAccount } from '../account';
import { defaultContact } from '../contact';
import { defaultDiscountState } from '../discount';
import { defaultInvoice } from '../invoice';
import { defaultPayment } from '../payment';
import { defaultPreview } from '../preview';
import { defaultNewSubscription } from '../subscription';
import { defaultUserSetting } from '../userSettings';
import { defaultCacheVersionState } from './cacheVersionState';
import { CheckoutState } from './CheckoutState';

export const emptyState: CheckoutState = {
  cacheVersion: defaultCacheVersionState,
  contact: defaultContact,
  newSubscription: defaultNewSubscription,
  account: defaultAccount,
  userSettings: defaultUserSetting,
  payment: defaultPayment,
  preview: defaultPreview,
  promoCode: defaultDiscountState,
  invoice: defaultInvoice,
};
