import { PreviewResponse } from '../api';
import { requestStatus } from '../common/requestStatus';

export interface Preview {
  value?: Omit<Exclude<PreviewResponse, { success: false }>, 'success'>;
  status: requestStatus;
}

export interface PreviewState {
  preview: Preview;
}

export const defaultPreview: Preview = {
  status: requestStatus.EMPTY,
};
