import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PaymentMethod } from '../api/types';
import ccIcon from '../assets/icons/CreditCard.svg';
import paypalIcon from '../assets/icons/PayPal.svg';
import sepaIcon from '../assets/icons/SEPA.svg';

const imgStyle = {
  marginRight: '8px',
  width: '29px',
  height: '29px',
};

const IMG: React.FC<{ src: string; text: string }> = ({ src, text }) => (
  <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
    <img style={imgStyle} src={src} alt='' /> <Typography>{text}</Typography>
  </Box>
);

export interface CurrentPaymentMethodDescriptionProps {
  paymentMethod: PaymentMethod;
}

export const CurrentPaymentMethodDescription = withTranslation()<
  CurrentPaymentMethodDescriptionProps & WithTranslation
>(({ t, paymentMethod }) => {
  switch (paymentMethod.Type) {
    case 'CreditCard':
      return <IMG src={ccIcon} text={paymentMethod.CreditCardMaskNumber} />;
    case 'PayPal':
      return <IMG src={paypalIcon} text={t('Registered PayPal')} />;
    case 'ACH':
      return <IMG src={sepaIcon} text={t('Registered ACH')} />;
    case 'SEPA':
      return <IMG src={sepaIcon} text={t('Registered SEPA')} />;
  }
  return null;
});
