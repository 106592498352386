import { Theme } from '@material-ui/core';
import { secondaryEnscapeOrange } from '../../colors';

export const muiISelectOverrides = (): Theme['overrides'] => ({
  MuiSelect: {
    icon: {
      color: secondaryEnscapeOrange,
    },
    selectMenu: {
      borderRadius: 0,
    },
  },
});
