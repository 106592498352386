import { useStoreActionDispatcher, useStoreKeyState } from '../context/storeContext';
import {
  addSubscription,
  removeSubscription,
  setSeats,
  setSubscribeSuccess,
  setSubscription,
} from './actions';

import { NewSubscriptionState } from './state';

export const useSubscription = () => ({
  subscription: useStoreKeyState<NewSubscriptionState>('newSubscription'),
  setSubscription: useStoreActionDispatcher(setSubscription),
  addSubscription: useStoreActionDispatcher(addSubscription),
  removeSubscription: useStoreActionDispatcher(removeSubscription),
  setSeats: useStoreActionDispatcher(setSeats),
  setSubscribeSuccess: useStoreActionDispatcher(setSubscribeSuccess),
});
