import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getVerifyVatId } from '../api';
import { requestStatus } from '../common/requestStatus';
import showStatus from '../common/showStatus';
import TextField from '../common/TextField';
import { useContact } from '../contact';
import { useBillingContext } from './BillingContext';
import euCountries from './EUCountries';

export const VATField = () => {
  const { t } = useTranslation();
  const {
    setContact,
    contact: { vatId, country, company },
  } = useContact();
  const { vatStatus, setVatStatus } = useBillingContext();
  const showVatInput = useMemo(() => euCountries.includes(country.toLowerCase()), [country]);
  const [helperText, setHelperText] = useState('');

  //Call API to validate VAT ID with every key input. Accepts only an empty textfield or a valid ID.
  useEffect(() => {
    if (!showVatInput || vatId === '') {
      return setVatStatus(requestStatus.EMPTY);
    }
    setVatStatus(requestStatus.PENDING);
    setHelperText('');
    const timer = setTimeout(async () => {
      try {
        const response = await getVerifyVatId(vatId);
        if (!response.data.ok) {
          setVatStatus(requestStatus.INVALID);
          setHelperText(`${t("Couldn't verify VAT ID")}. ${t('Please try another')}.`);
        } else if (!response.data.vat_id_is_valid) {
          setVatStatus(requestStatus.INVALID);
          setHelperText(`${t('VAT ID could not be found')}. ${t('Please try another')}.`);
        } else {
          setVatStatus(requestStatus.VALID);
          if (response.data.company_name && !company) {
            setContact({ company: response.data.company_name });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }, 200);

    return () => {
      clearTimeout(timer);
    };
    // we don't want to call the api everytime the company field changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatId, setVatStatus, setContact, showVatInput, t]);

  return (
    <>
      {showVatInput && (
        <TextField
          id='checkout-textfield-billing-vatid'
          label={t('VAT ID')}
          value={vatId}
          fullWidth
          error={vatStatus === requestStatus.INVALID || vatStatus === requestStatus.PENDING}
          onChange={(event) => setContact({ vatId: event.target.value })}
          helperText={helperText}
          InputProps={showStatus(vatStatus, 'VAT ID')}
          style={{ height: '48px' }}
        />
      )}
    </>
  );
};
