// eslint-disable-next-line no-restricted-imports
import { Paper as MUIPaper, PaperProps, styled } from '@material-ui/core';
import React, { forwardRef } from 'react';

/**
 * @author Konstantin Knieps
 * @desc A textfield
 * */

const StyledPaper = styled(MUIPaper)({
  '#root &': {
    padding: 10,
    borderRadius: 0,
    position: 'relative',
  },
});

export const Paper = forwardRef<HTMLDivElement, PaperProps>(function (props, ref) {
  return (
    <StyledPaper {...props} ref={ref}>
      {props.children}
    </StyledPaper>
  );
});

export default Paper;
