import { setZuoraAccount } from '../contact';
import { ReducerBuilder } from '../context/reducerBuilder';
import { resetState } from '../context/resetState';
import {
  setBearerToken,
  setDefaultCurrency,
  setUserIsLoggedIn,
  setUserIsLoggedOut,
} from './actions';
import { UserSettings } from './state';

export const userSettingsReducer = new ReducerBuilder<UserSettings>()
  .case(setUserIsLoggedOut, (state) => ({
    ...state,
    isLoggedIn: false,
  }))
  .case(setUserIsLoggedIn, (state) => ({
    ...state,
    isLoggedIn: true,
  }))
  .case(setDefaultCurrency, (state, defaultCurrency) => ({
    ...state,
    defaultCurrency,
  }))
  .case(setZuoraAccount, (state) => ({
    ...state,
    isLoggedIn: true,
  }))
  .case(setBearerToken, (state, bearerToken) => ({
    ...state,
    bearerToken,
  }))
  .case(resetState, (state) => ({
    ...state,
    isLoggedIn: false,
  }))
  .build();
