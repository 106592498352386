import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../common/Button';
import { usePortalHrefs } from '../common/usePortalHrefs';
import { cartPaths } from '../navigation/UrlPaths';

const EmailForm: React.FC = () => {
  const { auth } = usePortalHrefs();
  const history = useHistory();

  const createNewAccount = () => {
    history.push(cartPaths.BILLING);
  };

  return (
    <Grid container>
      <Grid>
        <Button color='primary' variant='contained' onClick={createNewAccount}>
          Create a new Account
        </Button>
      </Grid>
      <Grid>
        <Button color='secondary' variant='contained' href={auth}>
          Sign in with SSO
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmailForm;
