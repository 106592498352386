import { useStoreActionDispatcher, useStoreKeyState } from '../context/storeContext';
import { setContact, setZuoraAccount } from './actions';
import { ContactState } from './state';
export type { ContactState } from './state';

export const useContact = () => ({
  contact: useStoreKeyState<ContactState>('contact'),
  setContact: useStoreActionDispatcher(setContact),
  setZuoraAccount: useStoreActionDispatcher(setZuoraAccount),
});
