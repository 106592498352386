import { Contact, useContact } from '../contact';
import { Currency } from '../currency';
import { UserSettings, useUserSettings } from '../userSettings';
import { definedOrThrows } from '../utils/definedOrThrows';

export const getCurrency = (
  { defaultCurrency, isLoggedIn }: UserSettings,
  { currency }: Pick<Contact, 'currency'>,
): Currency => {
  if (!isLoggedIn) {
    return defaultCurrency;
  }
  return definedOrThrows(currency, 'logged in user has no currency');
};

export const useCurrency = () => getCurrency(useUserSettings().userSettings, useContact().contact);
