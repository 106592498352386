import { makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../common/TextField';
import { useContact } from '../contact';
import { countries } from './CountryCodes';

const useStyles = makeStyles({
  expandMoreIconBtn: {
    '& button': {
      backgroundColor: 'transparent !important',
      padding: '2px !important',
    },
  },
});

export const CountrySelector = () => {
  const classes = useStyles();
  const {
    setContact,
    contact: { country },
  } = useContact();
  const [countryChoice, setCountryChoice] = useState({
    code: country,
    name: countries.find((c) => c.code === country)?.name || '',
    codeISO3: countries.find((c) => c.code === country)?.codeISO3 || '',
  });
  const { t } = useTranslation();

  return (
    <Autocomplete
      id='checkout-select-billing-country'
      className={classes.expandMoreIconBtn}
      options={countries}
      value={countryChoice}
      onChange={(event, value) => {
        setCountryChoice(value);
        if (value.code) {
          setContact({ country: value.code });
        }
      }}
      disableClearable
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.code === value.code}
      renderOption={(option) => (
        <>
          {option.name} ({option.code})
        </>
      )}
      popupIcon={<ExpandMore color='primary' />}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Country')}
          fullWidth
          color='secondary'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};
