import { useEffect } from 'react';
import { useDiscount } from './useDiscount';

const getPromoCode = () => {
  const { search } = window.location;
  const query = new URLSearchParams(search);
  return query.get('promo');
};

export const useGlobalPromoCode = () => {
  const { setDiscount } = useDiscount();
  const promoCode = getPromoCode();
  useEffect(() => {
    if (promoCode) {
      setDiscount({ promoCode });
    }
  }, [promoCode, setDiscount]);
  return promoCode;
};
