import { useCallback, useEffect } from 'react';
import { postPreview } from '../api';
import { requestStatus } from '../common/requestStatus';
import { useDebounced } from '../common/useDebounced';
import { useContact } from '../contact';
import { useCurrency } from '../context/getCurrency';
import { useDiscount } from '../discount';
import { useSubscription } from '../subscription';
import { getTaxesTimeout } from './constants';
import { usePreview } from './usePreview';

export const usePreviewUpdates = () => {
  const { contact } = useContact();
  const { promoCode } = useDiscount();
  const { removeDiscount } = useDiscount();
  const { subscription } = useSubscription();
  const { setPreviewStatus, setPreview } = usePreview();
  const currency = useCurrency();
  const { city, country, zipCode, state, vatId } = contact;

  const getTaxes = useCallback(async (): ReturnType<typeof postPreview> => {
    try {
      return await postPreview({
        city,
        country,
        zipCode,
        state,
        currency,
        promoCode,
        vatId,
        ratePlans: subscription.map((s) => ({ ratePlanId: s.ratePlanId, seats: s.seats })),
      });
    } catch (e) {
      console.error(e);
      return {
        success: false,
        reasons: [{ message: (e as Error).toString(), code: 'unknown' }],
      };
    }
  }, [subscription, city, country, zipCode, state, currency, promoCode, vatId]);

  const [responsePreview, previewIsLoading] = useDebounced(getTaxes, {
    timeout: getTaxesTimeout,
    active: subscription.length > 0,
  });

  useEffect(() => {
    if (previewIsLoading) {
      return setPreviewStatus({
        status: requestStatus.PENDING,
      });
    }

    if (!responsePreview) {
      return setPreviewStatus({
        status: requestStatus.INVALID,
      });
    }

    if (!responsePreview.success) {
      if (promoCode && responsePreview.reasons[0]?.code === 'InvalidPromoCode') {
        return removeDiscount();
      }
      return setPreviewStatus({
        status: requestStatus.INVALID,
      });
    }
    const { success, ...preview } = responsePreview;
    setPreview({
      preview,
    });
  }, [responsePreview, previewIsLoading, promoCode, setPreview, setPreviewStatus, removeDiscount]);
};
