import {
  Box,
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  Link,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useEULALink } from './useEULALink';
import { usePayment } from './usePayment';

const useStyles = makeStyles({
  formControlLabel: {
    alignItems: 'start',
  },
  checkbox: {
    padding: '0 4px 0 7px',
  },
});

export const UserAgreementCheckbox: React.FC<Pick<FormControlLabelProps, 'label'>> = ({
  label,
}) => {
  const {
    toggleUserAgreementAccepted,
    setUserAgreementRequired,
    payment: { userAgreementAccepted },
  } = usePayment();
  const classes = useStyles();

  useEffect(() => {
    setUserAgreementRequired(true);
    return () => {
      setUserAgreementRequired(false);
    };
  }, [setUserAgreementRequired]);

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            id='checkout-checkbox-payment-eula'
            checked={userAgreementAccepted}
            onChange={() => toggleUserAgreementAccepted()}
            name='eula-checkbox'
            color='primary'
          />
        }
        label={label}
        className={classes.formControlLabel}
      />
    </Box>
  );
};

export const EULALabel = () => {
  const link = useEULALink();
  return (
    <Typography component='span' variant='body1'>
      <Trans i18nKey='acceptEnscapeEULA'>
        I accept the terms of the{' '}
        <StyledLink target='_blank' rel='noopener' href={link}>
          Enscape EULA
        </StyledLink>
      </Trans>
    </Typography>
  );
};

const StyledLink = styled(Link)(({ theme }) => ({
  '#root &': {
    cursor: 'pointer',
    textAlign: 'left',
    color: theme.palette.primary.main,
    '&:hover, &:active': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
}));
