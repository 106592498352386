import { Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { externalEndpoints } from '../api';
import ButtonPrimary from '../common/ButtonPrimary';
import { useInvoice } from '../invoice';
import { ThankYouBanner } from './ThankYouBanner';

export const useSuccessPageStyles = makeStyles({
  root: {
    height: '100%',
  },
  thankYouBannerContainer: {
    display: 'block',
    '@media (max-width: 599px)': {
      // mobile
      display: 'none',
    },
    '@media (min-width: 600px) and (max-width: 960px)': {
      // tablet
      display: 'none',
    },
  },
  successContainer: {
    padding: 24,
  },
  purchaseConfirmationContainer: {
    margin: '24px 0 39px 0',
  },
  successTitle: {
    margin: '15px 0 16px 0',
  },
  licenseTitle: {
    margin: '35px 0 6px 0',
  },

  activateLicenseInfo: {
    margin: '5px 0 20px 0',
  },

  knowledgeBaseLink: {
    textDecoration: 'none',
    color: '#FF7800',
    cursor: 'pointer',
  },
});

const SuccessKnownCustomer = () => {
  const classes = useSuccessPageStyles();
  const { invoice } = useInvoice();
  const { t } = useTranslation();

  const goToAccount = () => {
    window.open(externalEndpoints.accountLogin);
  };
  return (
    <Grid container className={classes.root}>
      <Grid item md={6} className={classes.successContainer}>
        <Grid item>
          <Typography variant='h3' className={classes.successTitle}>
            {t('Order Number')} {invoice.orderNumber}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <Trans i18nKey='activateLicenseLink'>
              Please follow the instructions outlined in the{' '}
              <Link target='_blank' rel='noopener' href={externalEndpoints.knowledgebase}>
                Knowledge Base
              </Link>{' '}
              to activate your license key.
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <ButtonPrimary
            fullWidth
            id='checkout-button-success-gotoaccount'
            color='primary'
            onClick={goToAccount}
          >
            {t('Go To Account')}
          </ButtonPrimary>
        </Grid>
      </Grid>
      <Grid item md={6} className={classes.thankYouBannerContainer}>
        <ThankYouBanner newUser={false} />
      </Grid>
    </Grid>
  );
};

export default SuccessKnownCustomer;
