import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { postPayPalBaid } from '../api';
import api from '../api/api';
import apiEndpoints from '../api/apiEndpoints';
import { baseURL } from '../api/baseUrl';
import { PaymentMethod } from '../api/types';
import { PayButton } from './PayButton';
import { PayButtonActionProps } from './usePaymentView';

const initiatePayment = async () => {
  const { data } = await api.get(apiEndpoints.paypalInit);
  window.open(data.uri, '_blank', 'width=600, height=800');
};
export const PayPalPayButton: React.FC<PayButtonActionProps> = ({ paymentAction }) => {
  const { t } = useTranslation();
  const { mutate } = useMutation(
    async (token: string): Promise<PaymentMethod> => {
      const paypalDetails = await postPayPalBaid(token);
      const { email, baid } = paypalDetails;

      return {
        PaypalBaid: baid,
        PaypalEmail: email,
        Type: 'PayPal',
        PayPalType: 'ExpressCheckout',
      };
    },
    {
      onSuccess(data) {
        paymentAction(data);
      },
    },
  );

  useEffect(() => {
    const handlePayPalCallbackEvent = ({ data, origin }: MessageEvent) => {
      if (data.type !== 'paypalData' || origin !== baseURL) {
        return;
      }
      mutate(data.token);
    };

    window.addEventListener('message', handlePayPalCallbackEvent);

    return () => {
      window.removeEventListener('message', handlePayPalCallbackEvent);
    };
  }, [mutate]);

  return <PayButton onClick={initiatePayment} label={t('checkout with paypal')} />;
};
