export const getZuoraErrorMessage = (key: string, code: string, message: string) => {
  if (key === 'creditCardNumber') {
    if (code === '001') {
      return 'Card number required. Please input the card number first.';
    }
    if (code === '002') {
      return 'Number does not match credit card. Please try again.';
    }
  }
  return message;
};
