import { setZuoraAccount } from '../contact';
import { ReducerBuilder } from '../context/reducerBuilder';
import { resetState } from '../context/resetState';
import { Account, defaultAccount } from './state';

export const accountReducer = new ReducerBuilder<Account>()
  .case(resetState, () => defaultAccount)
  .case(setZuoraAccount, (state, { zAcc }) => ({
    accountNumber: zAcc.basicInfo.accountNumber,
    accountId: zAcc.basicInfo.id,
  }))
  .build();
