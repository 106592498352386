export const secondaryEnscapeOrange = '#FF7800';
export const secondaryEnscapeOrangeHover = '#FF964D';
export const tertiaryOrangeHover = '#FFE4CC';
export const disabledColorSilver = '#A3A3A3';
export const primaryPurple = '#282042';
export const headline = '#111111';
export const done = '#27A764';
export const primaryGreySlate = '#787878';
export const primaryEnscapeOrange = '#F15A24';
export const positiveFeedback = '#00BF4C';
export const negativeFeedback = '#FF1D15';
export const infoFeedback = '#2196F3';
export const warningFeedback = '#FFA07A';
export const enscapeGradientOrange = 'linear-gradient(271.85deg, #F15A24, #EF9604)';
