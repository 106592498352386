// eslint-disable-next-line no-restricted-imports
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import * as fonts from '../fonts';
import { headline } from '../colors';

const baseFontSize = 16;

export const typography: TypographyOptions = {
  body1: {
    fontFamily: fonts.text,
    fontSize: baseFontSize,
    lineHeight: 1.8,
  },
  body2: {
    fontFamily: fonts.text,
    fontWeight: 400,
    fontSize: 0.875 * baseFontSize,
    lineHeight: 1.5,
  },
  h1: {
    fontFamily: fonts.title,
    fontWeight: 'bold',
    fontSize: 2.25 * baseFontSize,
    color: headline + '!important',
    lineHeight: 1.5,
  },
  h2: {
    fontFamily: fonts.title,
    fontWeight: 'bold',
    fontSize: 1.75 * baseFontSize,
    color: headline + '!important',
    lineHeight: 1.5,
  },
  h3: {
    fontFamily: fonts.title,
    fontWeight: 'bold',
    fontSize: 1.25 * baseFontSize,
    color: headline + '!important',
    lineHeight: 1.5,
  },
  button: {
    fontFamily: fonts.text,
    fontSize: baseFontSize,
    lineHeight: 1.6,
    textTransform: 'uppercase',
  },
};
