import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import darkLogo from '../assets/icons/dark.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    background: '#1B2042',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  bannerContent: {
    color: 'white !important',
    fontWeight: 900,
    fontSize: '3rem !important',
    zIndex: 5,
  },
  singUpText: {
    color: 'white !important',
    zIndex: 5,
  },
  bannerContentSpan: {
    background: 'linear-gradient(271.85deg, #F15A24, #EF9604)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  bannerImg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});

interface ThankYouBannerProps {
  newUser: boolean;
}

export const ThankYouBanner: React.FC<ThankYouBannerProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      className={classes.root}
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h1' className={classes.bannerContent}>
        <span className={classes.bannerContentSpan}>{t('THANK')}</span> {t('YOU!')}
      </Typography>
      {props.newUser ? (
        <Typography variant='h3' className={classes.singUpText}>
          {t('for signing up')}
        </Typography>
      ) : (
        ''
      )}
      <img src={darkLogo} alt='logoEnscape' className={classes.bannerImg} />
    </Grid>
  );
};
