import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ContextProvider } from './context/ContextProvider';
import { emptyState } from './context/emptyState';
import { MediaQueryProvider } from './context/MediaQueries';
import { reducer } from './context/reducer';
import { queryClient } from './queryClient';
import { useLoadSubscriptionPersistence } from './subscription';
import { BaseCss, UiProvider } from './ui';
import { Webshop } from './views/Webshop';

function App() {
  const newSubscription = useLoadSubscriptionPersistence();
  return (
    <ContextProvider emptyState={emptyState} reducer={reducer} value={{ newSubscription }}>
      <BaseCss>
        <UiProvider>
          <MediaQueryProvider>
            <QueryClientProvider client={queryClient}>
              <Webshop />
            </QueryClientProvider>
          </MediaQueryProvider>
        </UiProvider>
      </BaseCss>
    </ContextProvider>
  );
}

export default App;
