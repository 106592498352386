import React from 'react';
import { ProcessingAnimation } from '../common/ProcessingAnimation';
import { usePayment } from './usePayment';

export const PaymentProcessingAnimation: React.FC = () => {
  const {
    payment: { process },
  } = usePayment();
  const isProcessing = process === 'started';

  return <>{isProcessing && <ProcessingAnimation />}</>;
};
