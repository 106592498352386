import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { theme } from './theme';

const generateClassName = createGenerateClassName({
  productionPrefix: 'ws',
  disableGlobal: true,
});

type Props = Omit<React.ComponentProps<typeof ThemeProvider>, 'theme'>;

//Note: StylesProvider is used so that the styles don't overwrite the selfservice styles
export const UiProvider: React.FC<Props> = (props) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider {...props} theme={theme} />
    </StylesProvider>
  );
};
