import { PaymentMethod } from '../api/types';
import { Currency } from '../currency';

export interface Payment {
  defaultPaymentMethodId?: string;
  defaultPaymentMethod?: PaymentMethod;
  paymentMethodSuccess?: PaymentMethod;
  selectedPaymentMethodType: PaymentMethod['Type'] | 'default';
  usedPaymentType?: PaymentMethod['Type'];
  userAgreementAccepted: boolean;
  userAgreementRequired: boolean;
  showSpinner: boolean;
  paymentPageIsLoading: boolean;
  process: 'none' | 'started' | 'success' | 'error';
  errorMessage?: string;
  disablePaymentCount: number;
  disableSelectPaymentTypeCount: number;
  paymentAmount: number;
  paymentCurrency: Currency;
  paymentCountry: string;
  userPrefillData?: {
    creditCardHolderName: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

export interface PaymentState {
  payment: Payment;
}

export const defaultPayment: Payment = {
  selectedPaymentMethodType: 'PayPal',
  userAgreementRequired: false,
  userAgreementAccepted: false,
  showSpinner: false,
  paymentPageIsLoading: false,
  process: 'none',
  disablePaymentCount: 0,
  disableSelectPaymentTypeCount: 0,
  paymentAmount: 0,
  paymentCurrency: 'USD',
  paymentCountry: 'US',
};
