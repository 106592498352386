export const countries = [
  { code: 'US', codeISO3: 'USA', name: 'United States of America' },
  { code: 'AF', codeISO3: 'AFG', name: 'Afghanistan' },
  { code: 'AX', codeISO3: 'ALA', name: 'Aland Islands' },
  { code: 'AL', codeISO3: 'ALB', name: 'Albania' },
  { code: 'DZ', codeISO3: 'DZA', name: 'Algeria' },
  { code: 'AD', codeISO3: 'AND', name: 'Andorra' },
  { code: 'AO', codeISO3: 'AGO', name: 'Angola' },
  { code: 'AI', codeISO3: 'AIA', name: 'Anguilla' },
  { code: 'AQ', codeISO3: 'ATA', name: 'Antarctica' },
  { code: 'AG', codeISO3: 'ATG', name: 'Antigua And Barbuda' },
  { code: 'AR', codeISO3: 'ARG', name: 'Argentina' },
  { code: 'AM', codeISO3: 'ARM', name: 'Armenia' },
  { code: 'AW', codeISO3: 'ABW', name: 'Aruba' },
  { code: 'AU', codeISO3: 'AUS', name: 'Australia' },
  { code: 'AT', codeISO3: 'AUT', name: 'Austria' },
  { code: 'AZ', codeISO3: 'AZE', name: 'Azerbaijan' },
  { code: 'BS', codeISO3: 'BHS', name: 'Bahamas' },
  { code: 'BH', codeISO3: 'BHR', name: 'Bahrain' },
  { code: 'BD', codeISO3: 'BGD', name: 'Bangladesh' },
  { code: 'BB', codeISO3: 'BRB', name: 'Barbados' },
  { code: 'BY', codeISO3: 'BLR', name: 'Belarus' },
  { code: 'BE', codeISO3: 'BEL', name: 'Belgium' },
  { code: 'BZ', codeISO3: 'BLZ', name: 'Belize' },
  { code: 'BJ', codeISO3: 'BEN', name: 'Benin' },
  { code: 'BM', codeISO3: 'BMU', name: 'Bermuda' },
  { code: 'BT', codeISO3: 'BTN', name: 'Bhutan' },
  { code: 'BO', codeISO3: 'BOL', name: 'Bolivia' },
  { code: 'BQ', codeISO3: 'BES', name: 'Bonaire, Saint Eustatius and Saba' },
  { code: 'BA', codeISO3: 'BIH', name: 'Bosnia and Herzegovina' },
  { code: 'BW', codeISO3: 'BWA', name: 'Botswana' },
  { code: 'BV', codeISO3: 'BVT', name: 'Bouvet Island' },
  { code: 'BR', codeISO3: 'BRA', name: 'Brazil' },
  { code: 'IO', codeISO3: 'IOT', name: 'British Indian Ocean Territory' },
  { code: 'BN', codeISO3: 'BRN', name: 'Brunei Darussalam' },
  { code: 'BG', codeISO3: 'BGR', name: 'Bulgaria' },
  { code: 'BF', codeISO3: 'BFA', name: 'Burkina Faso' },
  { code: 'BI', codeISO3: 'BDI', name: 'Burundi' },
  { code: 'KH', codeISO3: 'KHM', name: 'Cambodia' },
  { code: 'CM', codeISO3: 'CMR', name: 'Cameroon' },
  { code: 'CA', codeISO3: 'CAN', name: 'Canada' },
  { code: 'CV', codeISO3: 'CPV', name: 'Cape Verde' },
  { code: 'KY', codeISO3: 'CYM', name: 'Cayman Islands' },
  { code: 'CF', codeISO3: 'CAF', name: 'Central African Republic' },
  { code: 'TD', codeISO3: 'TCD', name: 'Chad' },
  { code: 'CL', codeISO3: 'CHL', name: 'Chile' },
  { code: 'CN', codeISO3: 'CHN', name: 'China' },
  { code: 'CX', codeISO3: 'CXR', name: 'Christmas Island' },
  { code: 'CC', codeISO3: 'CCK', name: 'Cocos (Keeling) Islands' },
  { code: 'CO', codeISO3: 'COL', name: 'Colombia' },
  { code: 'KM', codeISO3: 'COM', name: 'Comoros' },
  { code: 'CG', codeISO3: 'COG', name: 'Congo' },
  { code: 'CD', codeISO3: 'COD', name: 'Congo the Democratic Republic of the' },
  { code: 'CK', codeISO3: 'COK', name: 'Cook Islands' },
  { code: 'CR', codeISO3: 'CRI', name: 'Costa Rica' },
  { code: 'CI', codeISO3: 'CIV', name: "Cote d'Ivoire" },
  { code: 'HR', codeISO3: 'HRV', name: 'Croatia' },
  { code: 'CU', codeISO3: 'CUB', name: 'Cuba' },
  { code: 'CW', codeISO3: 'CUW', name: 'Curacao' },
  { code: 'CY', codeISO3: 'CYP', name: 'Cyprus' },
  { code: 'CZ', codeISO3: 'CZE', name: 'Czech Republic' },
  { code: 'DK', codeISO3: 'DNK', name: 'Denmark' },
  { code: 'DJ', codeISO3: 'DJI', name: 'Djibouti' },
  { code: 'DM', codeISO3: 'DMA', name: 'Dominica' },
  { code: 'DO', codeISO3: 'DOM', name: 'Dominican Republic' },
  { code: 'EC', codeISO3: 'ECU', name: 'Ecuador' },
  { code: 'EG', codeISO3: 'EGY', name: 'Egypt' },
  { code: 'SV', codeISO3: 'SLV', name: 'El Salvador' },
  { code: 'GQ', codeISO3: 'GNQ', name: 'Equatorial Guinea' },
  { code: 'ER', codeISO3: 'ERI', name: 'Eritrea' },
  { code: 'EE', codeISO3: 'EST', name: 'Estonia' },
  { code: 'ET', codeISO3: 'ETH', name: 'Ethiopia' },
  { code: 'FK', codeISO3: 'FLK', name: 'Falkland Islands (Malvinas)' },
  { code: 'FO', codeISO3: 'FRO', name: 'Faroe Islands' },
  { code: 'FJ', codeISO3: 'FJI', name: 'Fiji' },
  { code: 'FI', codeISO3: 'FIN', name: 'Finland' },
  { code: 'FR', codeISO3: 'FRA', name: 'France' },
  { code: 'GF', codeISO3: 'GUF', name: 'French Guiana' },
  { code: 'PF', codeISO3: 'PYF', name: 'French Polynesia' },
  { code: 'TF', codeISO3: 'ATF', name: 'French Southern Territories' },
  { code: 'GA', codeISO3: 'GAB', name: 'Gabon' },
  { code: 'GM', codeISO3: 'GMB', name: 'Gambia' },
  { code: 'GE', codeISO3: 'GEO', name: 'Georgia' },
  { code: 'DE', codeISO3: 'DEU', name: 'Germany' },
  { code: 'GH', codeISO3: 'GHA', name: 'Ghana' },
  { code: 'GI', codeISO3: 'GIB', name: 'Gibraltar' },
  { code: 'GR', codeISO3: 'GRC', name: 'Greece' },
  { code: 'GL', codeISO3: 'GRL', name: 'Greenland' },
  { code: 'GD', codeISO3: 'GRD', name: 'Grenada' },
  { code: 'GP', codeISO3: 'GLP', name: 'Guadeloupe' },
  { code: 'GU', codeISO3: 'GUM', name: 'Guam' },
  { code: 'GT', codeISO3: 'GTM', name: 'Guatemala' },
  { code: 'GG', codeISO3: 'GGY', name: 'Guernsey' },
  { code: 'GN', codeISO3: 'GIN', name: 'Guinea' },
  { code: 'GW', codeISO3: 'GNB', name: 'Guinea-Bissau' },
  { code: 'GY', codeISO3: 'GUY', name: 'Guyana' },
  { code: 'HT', codeISO3: 'HTI', name: 'Haiti' },
  { code: 'HM', codeISO3: 'HMD', name: 'Heard Island and McDonald Islands' },
  { code: 'VA', codeISO3: 'VAT', name: 'Holy See (Vatican City State)' },
  { code: 'HN', codeISO3: 'HND', name: 'Honduras' },
  { code: 'HK', codeISO3: 'HKG', name: 'Hong Kong' },
  { code: 'HU', codeISO3: 'HUN', name: 'Hungary' },
  { code: 'IS', codeISO3: 'ISL', name: 'Iceland' },
  { code: 'IN', codeISO3: 'IND', name: 'India' },
  { code: 'ID', codeISO3: 'IDN', name: 'Indonesia' },
  { code: 'IR', codeISO3: 'IRN', name: 'Iran Islamic Republic of' },
  { code: 'IQ', codeISO3: 'IRQ', name: 'Iraq' },
  { code: 'IE', codeISO3: 'IRL', name: 'Ireland' },
  { code: 'IM', codeISO3: 'IMN', name: 'Isle of Man' },
  { code: 'IL', codeISO3: 'ISR', name: 'Israel' },
  { code: 'IT', codeISO3: 'ITA', name: 'Italy' },
  { code: 'JM', codeISO3: 'JAM', name: 'Jamaica' },
  { code: 'JP', codeISO3: 'JPN', name: 'Japan' },
  { code: 'JE', codeISO3: 'JEY', name: 'Jersey' },
  { code: 'JO', codeISO3: 'JOR', name: 'Jordan' },
  { code: 'KZ', codeISO3: 'KAZ', name: 'Kazakhstan' },
  { code: 'KE', codeISO3: 'KEN', name: 'Kenya' },
  { code: 'KI', codeISO3: 'KIR', name: 'Kiribati' },
  { code: 'KP', codeISO3: 'PRK', name: "Korea, Democratic People's Republic of" },
  { code: 'KR', codeISO3: 'KOR', name: 'Korea, Republic of' },
  { code: 'KW', codeISO3: 'KWT', name: 'Kuwait' },
  { code: 'KG', codeISO3: 'KGZ', name: 'Kyrgyzstan' },
  { code: 'LA', codeISO3: 'LAO', name: "Lao People's Democratic Republic" },
  { code: 'LV', codeISO3: 'LVA', name: 'Latvia' },
  { code: 'LB', codeISO3: 'LBN', name: 'Lebanon' },
  { code: 'LS', codeISO3: 'LSO', name: 'Lesotho' },
  { code: 'LR', codeISO3: 'LBR', name: 'Liberia' },
  { code: 'LY', codeISO3: 'LBY', name: 'Libyan Arab Jamahiriya' },
  { code: 'LI', codeISO3: 'LIE', name: 'Liechtenstein' },
  { code: 'LT', codeISO3: 'LTU', name: 'Lithuania' },
  { code: 'LU', codeISO3: 'LUX', name: 'Luxembourg' },
  { code: 'MO', codeISO3: 'MAC', name: 'Macao' },
  { code: 'MK', codeISO3: 'MKD', name: 'Macedonia, the Former Yugoslav Republic of' },
  { code: 'MG', codeISO3: 'MDG', name: 'Madagascar' },
  { code: 'MW', codeISO3: 'MWI', name: 'Malawi' },
  { code: 'MY', codeISO3: 'MYS', name: 'Malaysia' },
  { code: 'MV', codeISO3: 'MDV', name: 'Maldives' },
  { code: 'ML', codeISO3: 'MLI', name: 'Mali' },
  { code: 'MT', codeISO3: 'MLT', name: 'Malta' },
  { code: 'MH', codeISO3: 'MHL', name: 'Marshall Islands' },
  { code: 'MQ', codeISO3: 'MTQ', name: 'Martinique' },
  { code: 'MR', codeISO3: 'MRT', name: 'Mauritania' },
  { code: 'MU', codeISO3: 'MUS', name: 'Mauritius' },
  { code: 'YT', codeISO3: 'MYT', name: 'Mayotte' },
  { code: 'MX', codeISO3: 'MEX', name: 'Mexico' },
  { code: 'FM', codeISO3: 'FSM', name: 'Micronesia, Federated States of' },
  { code: 'MD', codeISO3: 'MDA', name: 'Moldova, Republic of' },
  { code: 'MC', codeISO3: 'MCO', name: 'Monaco' },
  { code: 'MN', codeISO3: 'MNG', name: 'Mongolia' },
  { code: 'ME', codeISO3: 'MNE', name: 'Montenegro' },
  { code: 'MS', codeISO3: 'MSR', name: 'Montserrat' },
  { code: 'MA', codeISO3: 'MAR', name: 'Morocco' },
  { code: 'MZ', codeISO3: 'MOZ', name: 'Mozambique' },
  { code: 'MM', codeISO3: 'MMR', name: 'Myanmar' },
  { code: 'NA', codeISO3: 'NAM', name: 'Namibia' },
  { code: 'NR', codeISO3: 'NRU', name: 'Nauru' },
  { code: 'NP', codeISO3: 'NPL', name: 'Nepal' },
  { code: 'NL', codeISO3: 'NLD', name: 'Netherlands' },
  { code: 'NC', codeISO3: 'NCL', name: 'New Caledonia' },
  { code: 'NZ', codeISO3: 'NZL', name: 'New Zealand' },
  { code: 'NI', codeISO3: 'NIC', name: 'Nicaragua' },
  { code: 'NE', codeISO3: 'NER', name: 'Niger' },
  { code: 'NG', codeISO3: 'NGA', name: 'Nigeria' },
  { code: 'NU', codeISO3: 'NIU', name: 'Niue' },
  { code: 'NF', codeISO3: 'NFK', name: 'Norfolk Island' },
  { code: 'MP', codeISO3: 'MNP', name: 'Northern Mariana Islands' },
  { code: 'NO', codeISO3: 'NOR', name: 'Norway' },
  { code: 'OM', codeISO3: 'OMN', name: 'Oman' },
  { code: 'PK', codeISO3: 'PAK', name: 'Pakistan' },
  { code: 'PW', codeISO3: 'PLW', name: 'Palau' },
  { code: 'PS', codeISO3: 'PSE', name: 'Palestinian Territory, Occupied' },
  { code: 'PA', codeISO3: 'PAN', name: 'Panama' },
  { code: 'PG', codeISO3: 'PNG', name: 'Papua New Guinea' },
  { code: 'PY', codeISO3: 'PRY', name: 'Paraguay' },
  { code: 'PE', codeISO3: 'PER', name: 'Peru' },
  { code: 'PH', codeISO3: 'PHL', name: 'Philippines' },
  { code: 'PN', codeISO3: 'PCN', name: 'Pitcairn' },
  { code: 'PL', codeISO3: 'POL', name: 'Poland' },
  { code: 'PT', codeISO3: 'PRT', name: 'Portugal' },
  { code: 'PR', codeISO3: 'PRI', name: 'Puerto Rico' },
  { code: 'QA', codeISO3: 'QAT', name: 'Qatar' },
  { code: 'RE', codeISO3: 'REU', name: 'Reunion' },
  { code: 'RO', codeISO3: 'ROU', name: 'Romania' },
  { code: 'RU', codeISO3: 'RUS', name: 'Russian Federation' },
  { code: 'RW', codeISO3: 'RWA', name: 'Rwanda' },
  { code: 'BL', codeISO3: 'BLM', name: 'Saint Barthelemy' },
  { code: 'SH', codeISO3: 'SHN', name: 'Saint Helena' },
  { code: 'KN', codeISO3: 'KNA', name: 'Saint Kitts and Nevis' },
  { code: 'LC', codeISO3: 'LCA', name: 'Saint Lucia' },
  { code: 'MF', codeISO3: 'MAF', name: 'Saint Martin (French part)' },
  { code: 'PM', codeISO3: 'SPM', name: 'Saint Pierre and Miquelon' },
  { code: 'VC', codeISO3: 'VCT', name: 'Saint Vincent and the Grenadines' },
  { code: 'WS', codeISO3: 'WSM', name: 'Samoa' },
  { code: 'SM', codeISO3: 'SMR', name: 'San Marino' },
  { code: 'ST', codeISO3: 'STP', name: 'Sao Tome and Principe' },
  { code: 'SA', codeISO3: 'SAU', name: 'Saudi Arabia' },
  { code: 'SN', codeISO3: 'SEN', name: 'Senegal' },
  { code: 'RS', codeISO3: 'SRB', name: 'Serbia' },
  { code: 'SC', codeISO3: 'SYC', name: 'Seychelles' },
  { code: 'SL', codeISO3: 'SLE', name: 'Sierra Leone' },
  { code: 'SG', codeISO3: 'SGP', name: 'Singapore' },
  { code: 'SX', codeISO3: 'SXM', name: 'Sint Maarten' },
  { code: 'SK', codeISO3: 'SVK', name: 'Slovakia' },
  { code: 'SI', codeISO3: 'SVN', name: 'Slovenia' },
  { code: 'SB', codeISO3: 'SLB', name: 'Solomon Islands' },
  { code: 'SO', codeISO3: 'SOM', name: 'Somalia' },
  { code: 'ZA', codeISO3: 'ZAF', name: 'South Africa' },
  { code: 'GS', codeISO3: 'SGS', name: 'South Georgia and the South Sandwich Islands' },
  { code: 'SS', codeISO3: 'SSD', name: 'South Sudan' },
  { code: 'ES', codeISO3: 'ESP', name: 'Spain' },
  { code: 'LK', codeISO3: 'LKA', name: 'Sri Lanka' },
  { code: 'SD', codeISO3: 'SDN', name: 'Sudan' },
  { code: 'SR', codeISO3: 'SUR', name: 'Suriname' },
  { code: 'SJ', codeISO3: 'SJM', name: 'Svalbard and Jan Mayen' },
  { code: 'SZ', codeISO3: 'SWZ', name: 'Swaziland' },
  { code: 'SE', codeISO3: 'SWE', name: 'Sweden' },
  { code: 'CH', codeISO3: 'CHE', name: 'Switzerland' },
  { code: 'SY', codeISO3: 'SYR', name: 'Syrian Arab Republic' },
  { code: 'TW', codeISO3: 'TWN', name: 'Taiwan' },
  { code: 'TJ', codeISO3: 'TJK', name: 'Tajikistan' },
  { code: 'TZ', codeISO3: 'TZA', name: 'Tanzania, United Republic of' },
  { code: 'TH', codeISO3: 'THA', name: 'Thailand' },
  { code: 'TL', codeISO3: 'TLS', name: 'Timor-Leste' },
  { code: 'TG', codeISO3: 'TGO', name: 'Togo' },
  { code: 'TK', codeISO3: 'TKL', name: 'Tokelau' },
  { code: 'TO', codeISO3: 'TON', name: 'Tonga' },
  { code: 'TT', codeISO3: 'TTO', name: 'Trinidad and Tobago' },
  { code: 'TN', codeISO3: 'TUN', name: 'Tunisia' },
  { code: 'TR', codeISO3: 'TUR', name: 'Turkey' },
  { code: 'TM', codeISO3: 'TKM', name: 'Turkmenistan' },
  { code: 'TC', codeISO3: 'TCA', name: 'Turks and Caicos Islands' },
  { code: 'TV', codeISO3: 'TUV', name: 'Tuvalu' },
  { code: 'UG', codeISO3: 'UGA', name: 'Uganda' },
  { code: 'UA', codeISO3: 'UKR', name: 'Ukraine' },
  { code: 'AE', codeISO3: 'ARE', name: 'United Arab Emirates' },
  { code: 'GB', codeISO3: 'GBR', name: 'United Kingdom' },
  { code: 'UM', codeISO3: 'UMI', name: 'United States Minor Outlying Islands' },
  { code: 'UY', codeISO3: 'URY', name: 'Uruguay' },
  { code: 'UZ', codeISO3: 'UZB', name: 'Uzbekistan' },
  { code: 'VU', codeISO3: 'VUT', name: 'Vanuatu' },
  { code: 'VE', codeISO3: 'VEN', name: 'Venezuela' },
  { code: 'VN', codeISO3: 'VNM', name: 'Viet Nam' },
  { code: 'VG', codeISO3: 'VGB', name: 'Virgin Islands, British' },
  { code: 'VI', codeISO3: 'VIR', name: 'Virgin Islands, U.S.' },
  { code: 'WF', codeISO3: 'WLF', name: 'Wallis and Futuna' },
  { code: 'EH', codeISO3: 'ESH', name: 'Western Sahara' },
  { code: 'YE', codeISO3: 'YEM', name: 'Yemen' },
  { code: 'ZM', codeISO3: 'ZMB', name: 'Zambia' },
  { code: 'ZW', codeISO3: 'ZWE', name: 'Zimbabwe' },
];
