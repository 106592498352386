import { Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { externalEndpoints, fetchVersionInfo } from '../api';
import ButtonDownload from '../common/ButtonDownload';
import ButtonPrimary from '../common/ButtonPrimary';
import { useInvoice } from '../invoice';
import { ThankYouBanner } from '../success/ThankYouBanner';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  thankYouBannerContainer: {
    display: 'block',
    '@media (max-width: 599px)': {
      // mobile
      display: 'none',
    },
    '@media (min-width: 600px) and (max-width: 960px)': {
      // tablet
      display: 'none',
    },
  },
  successContainer: {
    padding: 24,
  },
  titleSpacing: {
    margin: '30px 0 0 0',
  },
  licenseTitle: {
    margin: '0 0 10px 0',
  },
  downloadTitle: {
    margin: '0 0 10px 0',
  },
  downloadHeadline: {
    marginTop: '44px',
  },
  bodySpacing: {
    margin: '15px 0 0 0',
  },
});

function Download() {
  const classes = useStyles();
  const { invoice } = useInvoice();
  const { t } = useTranslation();

  const goToAccount = () => {
    window.open(externalEndpoints.accountLogin);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item md={6}>
        <Grid
          container
          className={classes.successContainer}
          justifyContent='center'
          alignItems='center'
          alignContent='flex-start'
        >
          <Grid item className={classes.downloadHeadline}>
            <Typography variant='h3'>{t('Welcome to Enscape!')}</Typography>
          </Grid>
          <Grid item className={classes.bodySpacing}>
            <Typography>
              {t('Thank you for signing up') +
                '. ' +
                t(
                  'If you want to activate Enscape later, please check your email or login to get back to your license key',
                ) +
                '.'}
            </Typography>
          </Grid>
          <Grid item className={classes.titleSpacing}>
            <Typography className={classes.downloadTitle} variant='h3'>
              {t('Ornder Number')} {invoice.orderNumber}
            </Typography>
          </Grid>
          <Grid item className={classes.titleSpacing}>
            <Typography className={classes.downloadTitle} variant='h3'>
              {t('Download Enscape')}
            </Typography>
            <DownloadEnscape />
          </Grid>
          <Grid item className={classes.titleSpacing}>
            <Typography variant='h3' className={classes.licenseTitle}>
              {t('License Key')}
            </Typography>
            <Typography>
              <Trans i18nKey='activateLicenseLink'>
                Please follow the instructions outlined in the{' '}
                <Link target='_blank' rel='noopener' href={externalEndpoints.knowledgebase}>
                  Knowledge Base
                </Link>{' '}
                to activate your license key.{' '}
              </Trans>
              .
            </Typography>
          </Grid>
          <Grid item>
            <ButtonPrimary
              fullWidth
              id='checkout-button-download-gotoaccount'
              color='primary'
              onClick={goToAccount}
            >
              {t('Go To Account')}
            </ButtonPrimary>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} className={classes.thankYouBannerContainer}>
        <ThankYouBanner newUser={true} />
      </Grid>
    </Grid>
  );
}

export const DownloadEnscape = () => {
  const { data: versionInfo } = useQuery('EnscapeVersionInfo', fetchVersionInfo);
  if (!versionInfo?.installPath) {
    return null;
  }
  return (
    <ButtonDownload
      label={decodeURIComponent(versionInfo.installPath)}
      href={externalEndpoints.downloadLink + versionInfo.installPath}
    />
  );
};

export default Download;
