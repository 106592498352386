import React, { useEffect } from 'react';
import { Catalog } from '../api';
import { useGetCatalog, useGetLocalization } from '../apiQueries';
import { useContact } from '../contact';
import { useGlobalPromoCode } from '../discount';
import { useUserSettings } from '../userSettings';
import { CardContainer } from './CardContainer';
import { AllProductGrid, PricingGridSkeletons } from './PricingGrid';

const useTrackedCatalog = () => {
  const promoCode = useGlobalPromoCode();
  const { data: catalogueResponse } = useGetCatalog(promoCode);

  return (
    catalogueResponse &&
    (Object.values(catalogueResponse)
      .map(({ data }) => data?.ratePlan)
      .filter(Boolean) as Catalog)
  );
};
const Pricing: React.FC = () => {
  useInitializeUserLocalization();
  const catalogueResponse = useTrackedCatalog();
  return (
    <CardContainer container spacing={0}>
      {!catalogueResponse ? (
        <PricingGridSkeletons />
      ) : (
        <AllProductGrid products={catalogueResponse} />
      )}
    </CardContainer>
  );
};

const useInitializeUserLocalization = () => {
  const { setContact } = useContact();
  const { setDefaultCurrency } = useUserSettings();
  const { data: localization } = useGetLocalization();
  useEffect(() => {
    if (!localization) {
      return;
    }
    const { currencyCode, countryCode } = localization;
    setContact({ country: countryCode });
    setDefaultCurrency(currencyCode);
  }, [localization, setContact, setDefaultCurrency]);
};

export default Pricing;
