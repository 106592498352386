import { ReducerBuilder } from '../context/reducerBuilder';
import { resetState } from '../context/resetState';
import { setContact, setZuoraAccount } from './actions';
import { Contact, defaultContact } from './state';

export const contactReducer = new ReducerBuilder<Contact>()
  .case(setContact, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .case(setZuoraAccount, (state, { zAcc }) => ({
    ...state,
    paymentAccountId: zAcc.basicInfo.id,
    email: zAcc.billToContact.workEmail,
    firstName: zAcc.billToContact.firstName,
    lastName: zAcc.billToContact.lastName,
    address: zAcc.billToContact.address1,
    city: zAcc.billToContact.city,
    country: zAcc.billToContact.country,
    state: zAcc.billToContact.state || 'NY',
    zipCode: zAcc.billToContact.zipCode,
    currency: zAcc.billingAndPayment.currency,
    vatId: zAcc.taxInfo?.VATId || '',
  }))
  .case(resetState, (state) => ({
    ...defaultContact,
    country: state.country,
    state: state.state,
  }))
  .build();
