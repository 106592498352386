import { useEffect } from 'react';
import { useGetPaymentMethod } from '../apiQueries';
import { useDisablePayment } from './useDisablePayment';
import { useDisableSelectPaymentType } from './useDisableSelectPaymentType';
import { usePayment } from './usePayment';

export const useLoadDefaultPaymentMethod = () => {
  const {
    setDefaultPaymentMethod,
    setSelectedPaymentType,
    payment: { defaultPaymentMethodId },
  } = usePayment();
  const { data, isLoading } = useGetPaymentMethod(defaultPaymentMethodId);

  useDisableSelectPaymentType(isLoading);
  useDisablePayment(isLoading);

  useEffect(() => {
    if (data && data.Type !== 'Other') {
      setDefaultPaymentMethod(data);
      setSelectedPaymentType('default');
    }
  }, [data, setDefaultPaymentMethod, setSelectedPaymentType]);
};
