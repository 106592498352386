import { useCallback, useEffect, useRef, useState } from 'react';

export const useDebounced = <T>(
  callback: () => Promise<T>,
  { timeout, active }: { timeout: number; active: boolean },
): [T | null, boolean] => {
  const resultRef = useRef<T | null>(null);
  const counterRef = useRef(1);
  const [isLoading, setIsLoading] = useState(true);
  const mountedRef = useRef(true);

  const wrappedCall = useCallback(async () => {
    const counter = counterRef.current;
    const value = await callback();
    return {
      counter,
      value,
    };
  }, [callback]);

  useEffect(() => {
    if (!active) {
      return;
    }
    mountedRef.current = true;
    counterRef.current++;
    if (counterRef.current === 1) {
      mountedRef.current && setIsLoading(true);
    }
    const timeoutId = setTimeout(async () => {
      const { counter, value } = await wrappedCall();
      if (counterRef.current === counter) {
        resultRef.current = value;
        counterRef.current = 0;
        mountedRef.current && setIsLoading(false);
      }
    }, timeout);
    return () => {
      clearTimeout(timeoutId);
      mountedRef.current = false;
    };
  }, [callback, timeout, counterRef, setIsLoading, wrappedCall, mountedRef, active]);

  return [resultRef.current, isLoading];
};
