import { accountReducer } from '../account';
import { contactReducer } from '../contact';
import { discountReducer } from '../discount';
import { invoiceReducer } from '../invoice';
import { paymentReducer } from '../payment';
import { previewReducer } from '../preview';
import { subscriptionReducer } from '../subscription';
import { userSettingsReducer } from '../userSettings';
import { cacheVersionReducer } from './cacheVersionReducer';
import { CheckoutState } from './CheckoutState';
import { mergeReducers } from './mergeReducers';

export const reducer = mergeReducers<CheckoutState>({
  cacheVersion: cacheVersionReducer,
  account: accountReducer,
  payment: paymentReducer,
  preview: previewReducer,
  contact: contactReducer,
  newSubscription: subscriptionReducer,
  userSettings: userSettingsReducer,
  promoCode: discountReducer,
  invoice: invoiceReducer,
});
