import { ReducerBuilder } from '../context/reducerBuilder';
import { resetState } from '../context/resetState';
import { setSubscribeSuccess } from '../subscription';
import { resetInvoice } from './actions';
import { defaultInvoice, Invoice } from './state';

export const invoiceReducer = new ReducerBuilder<Invoice>()
  .case(setSubscribeSuccess, (_, { orderNumber }) => ({ orderNumber }))
  .case(resetInvoice, () => defaultInvoice)
  .case(resetState, () => defaultInvoice)
  .build();
