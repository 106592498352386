import actionCreatorFactory from 'typescript-fsa';
import { UserSettings } from './state';

const actionCreator = actionCreatorFactory();
export const setUserIsLoggedOut = actionCreator('SET_USER_IS_LOGGED_OUT');
export const setDefaultCurrency = actionCreator<UserSettings['defaultCurrency']>(
  'SET_DEFAULT_CURRENCY',
);
export const setBearerToken = actionCreator<UserSettings['bearerToken']>('SET_BEARER_TOKEN');
export const setUserIsLoggedIn = actionCreator('SET_USER_IS_LOGGED_IN');
