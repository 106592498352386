import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Catalog } from '../api';
import { useSubscription } from '../subscription';

export interface LicenseSelectProps {
  catalog: Catalog;
  index: number;
}

export const LicenseSelect: React.FC<LicenseSelectProps> = ({ catalog, index }) => {
  const { setSubscription, subscription } = useSubscription();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedProduct = event.target.value as number;
    const selectedRatePlan = catalog[selectedProduct];

    setSubscription({
      subscription: {
        name: selectedRatePlan.name,
        billingCycle: selectedRatePlan.productDescription?.billingCycle,
        ratePlanId: selectedRatePlan.ratePlanId,
      },
      index,
    });
  };

  return (
    <Select
      variant={'outlined'}
      color={'primary'}
      onChange={handleChange}
      value={subscription[index].name}
    >
      {catalog.map(({ name: licenseName }, index) => (
        <MenuItem value={index} key={index}>
          {licenseName}
        </MenuItem>
      ))}
    </Select>
  );
};
