import { BillingCycle } from '@webdev/product-code';

export interface Subscription {
  billingCycle?: BillingCycle;
  ratePlanId: string;
  name: string;
  seats: number;
}

export interface License {
  invoiceNumber: string;
  invoiceId: string;
  key?: string;
  id?: string;
}

export type NewSubscription = Subscription[];

export interface NewSubscriptionState {
  newSubscription: NewSubscription;
}

export const defaultNewSubscription: NewSubscription = [];
