import { Grid, styled } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Catalog } from '../api';
import ButtonPrimary from '../common/ButtonPrimary';
import { ProductCard } from './ProductCard';

export interface CheckoutButtonProps {
  buttonId: string;
  onClick: () => void;
}

export const TrialCheckoutButton = withTranslation()<CheckoutButtonProps & WithTranslation>(
  ({ t }) => (
    <ButtonPrimary
      id={'checkout-button-Trial'}
      fullWidth
      color='primary'
      variant='outlined'
      className='trial-popup'
    >
      {t('Get my free trial')}
    </ButtonPrimary>
  ),
);

export const FloatingLicenceButton = withTranslation()<CheckoutButtonProps & WithTranslation>(
  ({ t, buttonId, onClick }) => (
    <ButtonPrimary id={buttonId} onClick={onClick} color='primary' fullWidth>
      {t('Proceed to checkout')}
    </ButtonPrimary>
  ),
);

export const FixedLicenseButton = withTranslation()<CheckoutButtonProps & WithTranslation>(
  ({ t, buttonId, onClick }) => (
    <ButtonPrimary id={buttonId} onClick={onClick} color='primary' variant='outlined' fullWidth>
      {t('Proceed to checkout')}
    </ButtonPrimary>
  ),
);

export interface PricingGridProps {
  products: Catalog;
}

export const AllProductGrid: React.FC<PricingGridProps> = ({ products }) => (
  <>
    {products.map((product) => (
      <Grid item xs={12} sm={4} id={product.name.replace(' ', '')} key={product.name}>
        <ProductCard
          product={{ ...product, productType: 'Paid' }}
          checkoutButton={FloatingLicenceButton}
        />
      </Grid>
    ))}
  </>
);

export const CardSkeleton = styled(Skeleton)({
  '@media (min-width: 961px)': {
    margin: '0 12px',
  },
});

CardSkeleton.displayName = 'CardSkeleton';

export const PricingGridSkeletons = () => (
  <>
    <Grid item xs={12} sm={4}>
      <CardSkeleton
        variant='rect'
        width={'100%'}
        height={500}
        aria-busy={true}
        role='progressbar'
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <CardSkeleton
        variant='rect'
        width={'100%'}
        height={500}
        aria-busy={true}
        role='progressbar'
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <CardSkeleton
        variant='rect'
        width={'100%'}
        height={500}
        aria-busy={true}
        role='progressbar'
      />
    </Grid>
  </>
);
