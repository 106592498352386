import { Backdrop, BackdropProps, styled, Theme } from '@material-ui/core';

export const CustomBackdrop = styled(Backdrop)<Theme, BackdropProps>(({ open }) => ({
  zIndex: open ? 1000 : 0,
  background: open ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
  opacity: '1 !important',
  visibility: 'visible !important' as any,
  position: open ? 'fixed' : 'relative',
}));
CustomBackdrop.displayName = 'CustomBackdrop';
