import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { captureException } from '../monitoring/monitoring';
import { sessionId } from '../session/makeSessionId';

export const axios = Axios;

export const makeAxiosClient = (config?: AxiosRequestConfig) => {
  const client = Axios.create(config);

  client.defaults.headers['X-SESSION-ID'] = sessionId;

  client.interceptors.response.use(
    (response) => response,
    function (error: AxiosError) {
      const isNetworkError = !error.response && error.request;
      const xhrUrl = `${error.config.baseURL || ''}${error.config.url || ''}`;
      const xhrStatus = isNetworkError ? 'network-error' : error.response?.status || 'none';
      const tags = { xhrUrl, xhrStatus };
      const fingerprint = Object.values(tags).map(String);

      error.message = isNetworkError
        ? `Network error fetching from ${xhrUrl}`
        : `Request to ${xhrUrl} failed with status code ${xhrStatus}`;

      captureException(error, { fingerprint, tags });

      return Promise.reject(error);
    },
  );
  return client;
};
