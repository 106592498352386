import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { usePayment } from './usePayment';

export const PaymentErrorMessage: React.FC<{ label: string }> = ({ label }) => {
  const {
    payment: { errorMessage },
  } = usePayment();
  return errorMessage ? (
    <FormHelperText error={true} id='PayButtonErrorMessage'>
      {label}
      <br />
      {errorMessage}
    </FormHelperText>
  ) : null;
};
