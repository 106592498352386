import actionCreatorFactory from 'typescript-fsa';
import { Payment } from './state';

const actionCreator = actionCreatorFactory('PAYMENT');
export const setSelectedPaymentType = actionCreator<Payment['selectedPaymentMethodType']>(
  'SET_SELECTED_PAYMENT_METHOD',
);

export const setDefaultMethodId = actionCreator<Payment['defaultPaymentMethodId']>(
  'SET_DEFAULT_METHOD_ID',
);

export const setUsedPaymentType = actionCreator<Payment['usedPaymentType']>('SET_PAYMENT_TYPE');

export const toggleUserAgreementAccepted = actionCreator('SET_USER_AGREEMENT_ACCEPTED');

export const setShowSpinner = actionCreator<Payment['showSpinner']>('SET_SHOW_SPINNER');

export const setPaymentPageIsLoading = actionCreator<Payment['paymentPageIsLoading']>(
  'SET_PAYMENT_PAGE_IS_LOADING',
);

export const startPayment = actionCreator('START_PAYMENT');

export const setPaymentSuccess = actionCreator<Payment['paymentMethodSuccess']>('PAYMENT_SUCCESS');
export const setPaymentError = actionCreator<string>('PAYMENT_ERROR');

export const setDefaultPaymentMethod = actionCreator<Payment['defaultPaymentMethod']>(
  'SET_DEFAULT_PAYMENT_METHOD',
);

export const setUserAgreementRequired = actionCreator<Payment['userAgreementRequired']>(
  'SET_USER_AGREEMENT_REQUIRED',
);

export const setDisablePayment = actionCreator('SET_DISABLE_PAYMENT');

export const setEnablePayment = actionCreator('SET_ENABLE_PAYMENT');

export const setPaymentAmount = actionCreator<Payment['paymentAmount']>('SET_PAYMENT_AMOUNT');

export const setPaymentCurrency = actionCreator<Payment['paymentCurrency']>('SET_PAYMENT_CURRENCY');

export const setPaymentCountry = actionCreator<Payment['paymentCountry']>('SET_PAYMENT_COUNTRY');

export const setDisableSelectPaymentType = actionCreator('SET_DISABLE_SELECT_PAYMENT_TYPE');

export const setEnableSelectPaymentType = actionCreator('SET_ENABLE_SELECT_PAYMENT_TYPE');

export const setUserPrefillData = actionCreator<Required<Payment['userPrefillData']>>(
  'SET_USER_PREFILL_DATA',
);
