import { Typography } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useContact } from '../contact';
import { colors } from '../ui';
import { countryWhitelist } from './countryWhitelist';

export const useIsBlockedCountry = () => {
  const { country } = useContact().contact;
  return !countryWhitelist.includes(country);
};

export const BlockedCountryMessage: React.ComponentType = withTranslation()(({ t }) => (
  <Typography
    style={{
      color: colors.primaryEnscapeOrange,
      fontSize: '11px',
      fontWeight: 'bold',
    }}
  >
    {t('Currently not available in your country')}
  </Typography>
));
