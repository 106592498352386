import React from 'react';
import { PayAmountLabel } from './PayAmountLabel';
import { PayButton } from './PayButton';
import { usePayment } from './usePayment';
import { PayButtonActionProps } from './usePaymentView';

export const DefaultPayment: React.FC = () => null;

export const DefaultPaymentPayButton: React.FC<PayButtonActionProps> = ({ paymentAction }) => {
  const {
    payment: { defaultPaymentMethod },
  } = usePayment();

  const onClick = () => {
    defaultPaymentMethod && paymentAction(defaultPaymentMethod);
  };
  return <PayButton onClick={onClick} label={<PayAmountLabel />} />;
};
