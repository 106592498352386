import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Cart } from '../assets/icons/Cart.svg';
import { paths } from '../navigation/UrlPaths';
import { colors } from '../ui';
import { Button } from './Button';

export const ClosedSidebarLink: React.FC = () => {
  const classes = useDrawerStyles();
  return (
    <Link className={classes.foldedSidebar} to={paths.CART} aria-label='open cart'>
      <div className={classes.foldedSidebarCart}>
        <Cart className={classes.foldedSidebarCartIcon} />
      </div>
      <div className={classes.foldedSidebarIcon} />
    </Link>
  );
};

export const ClosedSidebarButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const classes = useDrawerStyles();

  return (
    <Button
      className={classes.foldedSidebar}
      onClick={onClick}
      disableRipple
      aria-label='open cart'
    >
      <div className={classes.foldedSidebarCart}>
        <Cart className={classes.foldedSidebarCartIcon} />
      </div>
      <div className={classes.foldedSidebarIcon} />
    </Button>
  );
};

const useDrawerStyles = makeStyles({
  foldedSidebar: {
    overflow: 'hidden',
    width: '100%',
    minWidth: 'unset',
    height: '100vh',
    padding: 0,
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@media (max-width: 960px)': {
    foldedSidebar: {
      width: 0,
      display: 'none',
    },
  },
  foldedSidebarIcon: {
    width: 0,
    border: '#EF9604 2px solid',
    height: 30,
    borderRadius: 10,
    boxSizing: 'border-box',
    backgroundColor: '#EF9604',
  },
  foldedSidebarCart: {
    width: '100%',
    background: colors.enscapeGradientOrange,
    height: 80,
    transition: 'all 1s ease-in',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  foldedSidebarCartIcon: {
    height: 30,
  },
});
