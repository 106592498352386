import actionCreatorFactory from 'typescript-fsa';
import { Subscription } from './state';

const actionCreator = actionCreatorFactory('SUBSCRIPTION');

export const setSubscription = actionCreator<{
  subscription: Pick<Subscription, 'ratePlanId' | 'name' | 'billingCycle'>;
  index: number;
}>('SET_SUBSCRIPTION');
export const addSubscription = actionCreator<
  Pick<Subscription, 'ratePlanId' | 'name' | 'billingCycle'>
>('ADD_SUBSCRIPTION');

export const removeSubscription = actionCreator<number>('REMOVE_SUBSCRIPTION');

export const setSeats = actionCreator<{ seats: number; index: number }>('SET_SEATS');

export const setSubscribeSuccess = actionCreator<{
  orderNumber: string;
}>('SET_SUBSCRIBE_SUCCESS');
