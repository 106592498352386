import { Box, Container } from '@material-ui/core';
import React from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import { LanguageSelect } from '../common/LanguageSelect';
import CurrencySwitch from '../pricing/CurrencySwitch';
import Pricing from '../pricing/Pricing';
import { useSubscriptionPersistence } from '../subscription';
import { useChangeCurrencyEvent } from '../userSettings';
import { WebshopRoutes } from './WebshopRoutes';

export const Webshop = () => {
  useSubscriptionPersistence();
  useChangeCurrencyEvent();
  return (
    <HashRouter>
      <Box style={{ position: 'fixed' }}>
        <LanguageSelect />
        <CurrencySwitch />
      </Box>
      <Container>
        <Pricing />
        <Switch>
          <WebshopRoutes />
        </Switch>
      </Container>
    </HashRouter>
  );
};
