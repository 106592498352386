import { Currency } from '../currency';

export interface UserSettings {
  defaultCurrency: Currency;
  isLoggedIn: boolean;
  language: string;
  bearerToken?: string;
}

export interface UserSettingsState {
  userSettings: UserSettings;
}

export const defaultUserSetting: UserSettings = {
  defaultCurrency: 'USD',
  language: 'en',
  isLoggedIn: false,
};
