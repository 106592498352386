import { postSubscribeCreate, SubscriptionPayloadSchema } from '../api';
import { CheckoutState } from '../context/CheckoutState';
import { getCurrency } from '../context/getCurrency';

export type SubscribeStateParameters = Pick<
  CheckoutState,
  'newSubscription' | 'contact' | 'promoCode' | 'userSettings' | 'preview'
>;

const webshopSubscribe = (
  state: SubscribeStateParameters,
  payment: unknown, //TODO PBI 106560
) => {
  const {
    contact,
    newSubscription,
    promoCode,
    preview: { value },
  } = state;

  const currency = getCurrency(state.userSettings, state.contact);

  const promotion = value?.invoiceItems.find((i) => i.productName === 'Promotion');

  const { paymentAccountId, state: contactState, ...accountDetails } = contact;
  return postSubscribeCreate(
    {
      ...accountDetails,
      ...(contactState && { state: contactState }),
      currency,
    },
    payment as SubscriptionPayloadSchema['paymentMethod'],
    newSubscription,
    Boolean(contact.paymentAccountId),
    promotion && promoCode,
  );
};

export default webshopSubscribe;
