import { Grid, withStyles } from '@material-ui/core';

export const CardContainer = withStyles(() => ({
  root: {
    paddingTop: 30,
    paddingLeft: 'unset',
    paddingRight: 'unset',
    margin: 'unset',
    marginTop: 80,
    width: '100%',
    '@media (min-width: 600px) and (max-width: 960px)': {
      // tablet
      paddingLeft: 0,
      paddingRight: 0,
    },
    '@media (min-width: 961px)': {
      // desktop
      margin: 0,
      marginTop: 60,
    },
  },
}))(Grid);
