import { baseURL } from './baseUrl';

const apiEndpoints = {
  vat: '/api/vat/',
  address: '/api/address',
  invoice: `${baseURL}/api/invoice`,
  download: `${baseURL}/api/download/billing`,
  EULA: `${baseURL}/api/download/eula`,
  getPreview: '/api/subscriptions/preview',
  getDiscount: '/api/discount',
  paymentMethod: '/api/payment/method/',
  verifyCookie: '/api/verifycookie',
  paypalInit: '/api/paypal/init',
  subscriptionCreate: '/api/subscriptions',
  pageSignature: '/api/payment/page-signature/',
  subscriptionLicenseKey: '/api/subscription/licensekey/',
};

export default apiEndpoints;
