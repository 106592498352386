import { makeStyles, Theme } from '@material-ui/core';

export const sidebarOpenSize = '725px';

export const sidebarClosedSize = '50px';

export const useDrawerPaperStyles = makeStyles<Theme, { open: boolean; hideFoldedDrawer: boolean }>(
  {
    drawerPaper: {
      '@media (max-width: 960px)': {
        // mobile + tablet
        width: ({ open }) => (open ? '100%' : 0),
      },
      '@media (min-width: 961px)': {
        // desktop
        width: ({ open, hideFoldedDrawer }) => {
          if (hideFoldedDrawer) {
            return 0;
          }
          return open ? sidebarOpenSize : sidebarClosedSize;
        },
      },
      transition: 'width 200ms ease-out',
      border: 'none',
      boxShadow: '0px 0px 6px 1px rgba(0, 0, 0, 0.25)',
    },
  },
);
