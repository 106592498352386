import { Theme } from '@material-ui/core';

export const muiInputBaseOverrides = (): Theme['overrides'] => ({
  MuiInputBase: {
    root: {
      display: 'flex',
      textOverFlow: 'ellipsis',
    },
  },
});
