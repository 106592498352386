import { Currency } from '../currency';

export interface Contact {
  paymentAccountId?: string;
  firstName: string;
  lastName: string;
  country: string;
  zipCode: string;
  address: string;
  city: string;
  vatId: string;
  company: string;
  state?: string;
  email: string;
  currency?: Currency;
}

export interface ContactState {
  contact: Contact;
}

export const defaultContact: Contact = {
  email: '',
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  country: 'US',
  zipCode: '',
  company: '',
  state: '',
  vatId: '',
};
