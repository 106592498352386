import { styled, TextField as MUITextfield, TextFieldProps } from '@material-ui/core';
import React, { forwardRef } from 'react';

const StyledTextField = styled(MUITextfield)({
  '#root &': {
    margin: '8px 0',
  },
});

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(function (props, ref) {
  return (
    <StyledTextField {...props} ref={ref} fullWidth>
      {props.children}
    </StyledTextField>
  );
});

export default TextField;
