import { SanitizedProductRatePlan } from '../api';

type ProductType = 'Paid' | 'Trial';

interface ProductBase<RateType extends ProductType = any> {
  productType: RateType;
}

export interface TrialProduct extends ProductBase<'Trial'> {
  name: '14-Day-Trial';
}

export interface PaidProduct extends ProductBase<'Paid'>, SanitizedProductRatePlan {
  name: string;
  ratePlanId: string;
}

export type Product = TrialProduct | PaidProduct;

export const isTrialProduct = (product: Product): product is TrialProduct =>
  product.productType === 'Trial';
export const isPaidProduct = (product: Product): product is PaidProduct =>
  product.productType === 'Paid';
