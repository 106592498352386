import { makeStyles, styled, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EnscapeCube } from '../assets/icons/EnscapeCube.svg';
import { CircularProgress } from './CircularProgress';

export const ProcessingAnimation: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Container id='checkout-processing-animation'>
      <ContainerContent>
        <Typography variant='body1' className={classes.processingText}>
          {t('Your data is being processed')}
        </Typography>
        <div className={classes.animationContainer}>
          <EnscapeCube className={classes.cube} />
          <CircularProgress
            className={classes.spinner}
            size={200}
            thickness={1}
            role='progressbar'
          />
        </div>
      </ContainerContent>
    </Container>
  );
};

const Container = styled('div')({
  '#root &': {
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: '#ffffff',
    width: '100%',
    height: '100%',
    zIndex: 9999,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  },
});

const ContainerContent = styled('div')({
  '#root &': {
    marginTop: 75,
    position: 'fixed',
  },
});

const useStyles = makeStyles({
  cube: {
    width: '125px',
    height: '125px',
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    strokeDasharray: 100,
    strokeDashoffset: 100,
    strokeWidth: 2,
    animation: 'dashdraw 750ms ease-out forwards',
    stroke: '#ef9604',
  },
  spinner: {
    width: '100%',
    margin: 'auto',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    overflow: 'hidden',
    color: '#ef9604',
  },
  processingText: {
    textAlign: 'center',
    padding: '0 10px',
  },
  animationContainer: {
    overflow: 'hidden',
    position: 'relative',
    minWidth: 300,
    minHeight: 300,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
