import { PropTypes, styled, Theme as DefaultTheme } from '@material-ui/core';
import type { CreateCSSProperties } from '@material-ui/styles/withStyles';
import { colors } from '../ui';
import { Button } from './Button';

const StyledButton = styled(Button)<
  DefaultTheme,
  { color: PropTypes.Color; variant?: 'text' | 'outlined' | 'contained' }
>(({ theme, color, variant }) => {
  const extraCss: Record<string, CreateCSSProperties> = {};
  if (color === 'primary') {
    // The following "if" is to override Elementor's global theme style kit
    if (variant === 'outlined') {
      extraCss['#root &'] = {
        backgroundColor: 'white',
        color: colors.secondaryEnscapeOrange,
      };
    }
    extraCss[
      '&:focus, &:hover, [type=button]:focus, [type=button]:hover, [type=submit]:focus, [type=submit]:hover, button:focus, button:hover'
    ] = {
      backgroundColor:
        variant === 'outlined'
          ? colors.tertiaryOrangeHover + '!important'
          : colors.secondaryEnscapeOrangeHover + '!important',
      outline: 'none',
    };
    extraCss['&:disabled'] = {
      backgroundColor: colors.disabledColorSilver,
      color:
        variant === 'outlined' ? colors.disabledColorSilver : theme.palette[color].contrastText,
    };
  }
  return {
    '#root &': {
      borderRadius: 0,
      boxShadow: 'none',
      margin: '8px 24px',
      marginLeft: 0,
      boxSizing: 'border-box',
    },
    ...extraCss,
  };
});

export default StyledButton;
StyledButton.defaultProps = {
  variant: 'contained',
};
