import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en/resource';
import de from './de/resource';
import es from './es/resource';
import it from './it/resource';
import fr from './fr/resource';
import pt from './pt/resource';
import ja from './ja/resource';
import { currencies } from '../currency';

/**
 * @author Konstantin Knieps
 * @desc Configuration file for handling translations
 * Notes:
 * i18n = abbreviation for internationalization
 * Dots ( . ) are reserved for key separators and should not be included in translation fields
 *
 * Incorrect: "Thank you for signing up."
 * Correct:   "Thank you for signing up"
 *
 * */

i18n
  .use({
    type: 'languageDetector',
    init: function () {
      /* use services and options */
    },
    detect: function () {
      return (document.documentElement.lang.split('-')[0] || 'en').toLowerCase();
    },
    cacheUserLanguage: function () {
      /* cache language */
    },
  })
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      es: {
        translation: es,
      },
      it: {
        translation: it,
      },
      fr: {
        translation: fr,
      },
      pt: {
        translation: pt,
      },
      ja: {
        translation: ja,
      },
    },
    interpolation: {
      formatSeparator: ',',
      format: function (money, format, lng) {
        const moneyData = JSON.parse(money);
        if (currencies.find((e) => e === moneyData.currency)) {
          if (!moneyData.amount > 0) {
            moneyData.amount = 0;
          }
          return new Intl.NumberFormat(lng, { style: format, currency: moneyData.currency }).format(
            moneyData.amount,
          );
        } else {
          return 'invalid';
        }
      },
    },
    fallbackLng: 'en',
    saveMissing: true,
  });

export default i18n;
