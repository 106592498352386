export const paths = {
  PRICING: '/',
  CART: '/cart',
  INVOICE: '/invoices',
};

export const cartPaths = {
  LOGIN: '/login',
  BILLING: '/billing',
  PAYMENT: '/payment',
  SUCCESS: '/success',
  DOWNLOAD: '/download',
};
