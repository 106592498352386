import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { withStyles } from '@material-ui/core/styles';

export const BaseCss = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    '& input': {
      border: 0,
      padding: '6px 0 7px',
      transition: 'initial',
    },
  },
}))(ScopedCssBaseline);
