import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPaymentMethod } from '../api';
import api from '../api/api';
import apiEndpoints from '../api/apiEndpoints';
// @ts-ignore
import { SkeletonWithText } from '../common/CustomSkeleton';
import { Config, useConfig } from '../config';
// @ts-ignore
import Zuora from '../zuora/zuora';
import { getZuoraErrorMessage } from './getZuoraErrorMessage';
import { PayAmountLabel } from './PayAmountLabel';
import { PayButton } from './PayButton';
import { Payment } from './state';
import { usePayment } from './usePayment';
import { PayButtonActionProps } from './usePaymentView';

const getZuoraPublicHostedPageLite = (host: string) =>
  `https://${host}/apps/PublicHostedPageLite.do`;

interface ZuoraSignature {
  signature: any;
  token: any;
  tenantId: number;
  key: any;
  style: string;
  id: any;
  submitEnabled: boolean;
  url: string;
  locale: string;
  authorizationAmount: number;
  field_currency: string;
}

const ZuoraComponent = React.memo(() => {
  return <div id='zuora_payment' />;
});

export const ZuoraPayment: React.FC = () => {
  const { t } = useTranslation();
  const {
    payment: { paymentPageIsLoading },
  } = usePayment();
  return (
    <>
      <ZuoraComponent />
      {paymentPageIsLoading ? (
        <SkeletonWithText
          loadingText={t('Loading Secure Payment Method Transmission')}
          variant='rect'
          animation='pulse'
          width={'100%'}
          height={'300px'}
        />
      ) : null}
    </>
  );
};

export const ZuoraPayButton: React.FC<PayButtonActionProps> = ({ paymentAction }) => {
  const paymentPageSignature = useZuoraPaymentSignature();
  const handleClick = useZuoraFrame(paymentAction, paymentPageSignature);

  return <PayButton onClick={handleClick} label={<PayAmountLabel />} />;
};

const useZuoraFrame = (
  paymentAction: PayButtonActionProps['paymentAction'],
  paymentPageSignature?: ZuoraSignature,
) => {
  const {
    setShowSpinner,
    setPaymentPageIsLoading,
    payment: { userPrefillData },
  } = usePayment();

  const { t } = useTranslation();

  useEffect(() => {
    if (!paymentPageSignature) {
      return;
    }
    const successCallback = async (response: { success: boolean; refId: string }) => {
      if (!response.success) {
        // TODO: notify
        return console.log(response);
      }

      const paymentMethod = await getPaymentMethod(response.refId);
      paymentAction(paymentMethod);
      setShowSpinner(false);
    };

    const errorCallBack = (key: string, code: string, message: string) => {
      // TODO: Overwrite error messages generated by client-side validation
      setShowSpinner(false);
      const errorMessage = getZuoraErrorMessage(key, code, message);
      Zuora.sendErrorMessageToHpm(key, t(errorMessage));
    };

    Zuora.renderWithErrorHandler(
      paymentPageSignature,
      userPrefillData,
      successCallback,
      errorCallBack,
      '100%',
    );

    const load = () => {
      setPaymentPageIsLoading(false);
    };
    const zuoraFrame = document.getElementById('z_hppm_iframe');
    zuoraFrame?.addEventListener('load', load);
    return () => {
      zuoraFrame?.removeEventListener('load', load);
    };
  }, [
    paymentPageSignature,
    t,
    userPrefillData,
    paymentAction,
    setShowSpinner,
    setPaymentPageIsLoading,
  ]);

  return () => {
    setShowSpinner(true);
    Zuora.submit();
  };
};

const useZuoraPaymentSignature = () => {
  const {
    zuora: { host },
  } = useConfig();
  const {
    setPaymentPageIsLoading,
    payment: { selectedPaymentMethodType, paymentCountry, paymentAmount, paymentCurrency },
  } = usePayment();

  const [paymentPageSignature, setPaymentPageSignature] = useState<ZuoraSignature>();
  const config = useConfig();

  useEffect(() => {
    setPaymentPageIsLoading(true);
    const getPaymentPageSignature = async () => {
      try {
        const localizedPaymentMethod = getLocalizedPaymentMethod(
          selectedPaymentMethodType,
          paymentCountry,
        );
        const hpmId = config.zuora.hpm[localizedPaymentMethod];
        const { data } = await api.get(apiEndpoints.pageSignature + hpmId);

        const params: ZuoraSignature = {
          signature: data.signature,
          token: data.token,
          tenantId: Number(data.tenantId),
          key: data.key,
          style: 'inline',
          id: hpmId,
          submitEnabled: true,
          url: getZuoraPublicHostedPageLite(host),
          locale: 'en',
          authorizationAmount: paymentAmount,
          field_currency: paymentCurrency,
        };

        setPaymentPageSignature(params);
      } catch (err) {
        console.error(err);
      }
    };

    getPaymentPageSignature();
  }, [
    host,
    paymentCountry,
    paymentAmount,
    paymentCurrency,
    setPaymentPageSignature,
    selectedPaymentMethodType,
    config.zuora.hpm,
    setPaymentPageIsLoading,
  ]);
  return paymentPageSignature;
};

const getLocalizedPaymentMethod = (
  methodType: Payment['selectedPaymentMethodType'],
  country: string,
): keyof Config['zuora']['hpm'] => {
  if (methodType === 'CreditCard' && country === 'US') {
    return 'creditCardUS';
  }
  switch (methodType) {
    case 'CreditCard':
      return 'creditCard';
    case 'ACH':
      return 'ach';
    case 'SEPA':
      return 'sepa';
  }

  throw new Error(`methodType ${methodType} has no hpm`);
};
