import { useEffect, useMemo } from 'react';
import { useConfig } from '../config';
import { CacheVersionState, defaultCacheVersionState } from './cacheVersionState';
import { getStorageKey } from './getStorageKey';

export interface StoredData<Data> extends CacheVersionState {
  data: Data;
}

export const useLocalStoragePersistence = <State>(key: string, state: State) => {
  const { ENVIRONMENT } = useConfig();

  useEffect(() => {
    const storedDate: StoredData<State> = {
      cacheVersion: defaultCacheVersionState,
      data: state,
    };
    localStorage.setItem(getStorageKey(ENVIRONMENT, key), JSON.stringify(storedDate));
  }, [ENVIRONMENT, state, key]);
};

export const useLoadFromLocalStorage = <State>(key: string, emptyValue: State): State => {
  const { ENVIRONMENT } = useConfig();
  return useMemo(() => {
    const value = localStorage.getItem(getStorageKey(ENVIRONMENT, key));

    if (!value) {
      return emptyValue;
    }
    const stored = JSON.parse(value) as StoredData<State>;
    if (defaultCacheVersionState !== stored.cacheVersion) {
      return emptyValue;
    }
    return stored.data;
  }, [ENVIRONMENT, emptyValue, key]);
};
