// eslint-disable-next-line no-restricted-imports
import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';

export const breakpoints: BreakpointsOptions = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};
