import apiEndpoints from '../api/apiEndpoints';
import { useContact } from '../contact';
import { useDiscount } from '../discount';

export const useEULALink = () => {
  const {
    contact: { country },
  } = useContact();
  const { promoCode } = useDiscount();
  const url = new URL(apiEndpoints.EULA);
  country && url.searchParams.set('country', country);
  promoCode && url.searchParams.set('promoCode', promoCode);
  return url.href;
};
