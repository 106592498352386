import { makeStyles, styled, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Paper from '../common/Paper';
import { Contact, useContact } from '../contact';
import { useCurrency } from '../context/getCurrency';
import { countries } from './CountryCodes';

export const BillingOverview: React.FC<
  Omit<DisconnectedBillingOverviewProps, 'contact' | 'currency'>
> = (props) => {
  const { contact } = useContact();
  const currency = useCurrency();
  return <DisconnectedBillingOverview contact={contact} currency={currency} {...props} />;
};

export interface DisconnectedBillingOverviewProps {
  contact: Contact;
  currency?: string;
  editable?: boolean;
}

/**
 * @deprecated use BillingOverview
 */
export const DisconnectedBillingOverview = withTranslation()<
  DisconnectedBillingOverviewProps & WithTranslation
>(({ t, currency, contact, children, editable }) => {
  const classes = usePaperStyle({ editable });

  return (
    <>
      <Typography variant='h3'>{t('Billing Information')}</Typography>
      <Paper variant={'outlined'} className={classes.root}>
        <StyledTypography>{contact.email}</StyledTypography>
        <StyledTypography>
          {contact.company && contact.company} {contact.vatId && ', ' + contact.vatId}
        </StyledTypography>
        <StyledTypography>
          {contact.firstName} {contact.lastName}
        </StyledTypography>
        <StyledTypography>{contact.address}</StyledTypography>
        <StyledTypography>
          {contact.zipCode} {contact.city}
        </StyledTypography>
        <StyledTypography>
          {countries.find((c) => c.code === contact?.country)?.name || contact.country}
        </StyledTypography>
        <StyledTypography>
          {t('Currency')}: {currency}
        </StyledTypography>
        {children}
      </Paper>
    </>
  );
});

const StyledTypography = styled(Typography)({
  '#root &': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const usePaperStyle = makeStyles<Theme, { editable?: boolean }>((theme) => ({
  root: {
    margin: '8px 0',
    background: '#f0f0f0',
    color: ({ editable }) => (editable ? theme.palette.text.primary : '#b6b6b6'),
  },
}));
