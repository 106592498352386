import { useMutation } from 'react-query';
import { PaymentMethod, PostSubscriptionResponse } from '../api';
import { usePayment } from '../payment';
import { useSubscription } from '../subscription';

export const useMakeNewSubscription = (
  makeNewSubscription: (paymentMethod: PaymentMethod) => Promise<PostSubscriptionResponse>,
) => {
  const { setSubscribeSuccess } = useSubscription();
  const { startPayment, setPaymentError, setPaymentSuccess } = usePayment();
  const { mutate } = useMutation(makeNewSubscription, {
    onMutate() {
      startPayment();
    },
    onError(error) {
      setPaymentError((error as Response).statusText);
    },
    onSuccess(data, variables) {
      if (!data.success) {
        setPaymentError(data.error);
        return;
      }
      setPaymentSuccess(variables);
      setSubscribeSuccess({
        orderNumber: data.orderNumber,
      });
    },
  });
  return mutate;
};
