import { Grid } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import fixedLicense from '../assets/icons/fixed-seat-license.svg';
import floatingLicense from '../assets/icons/floating-seat-license.svg';
import trialLicense from '../assets/icons/trial-and-fixed-seat.svg';
import { useCurrency } from '../context/getCurrency';
import { useInvoice } from '../invoice';
import { paths } from '../navigation/UrlPaths';
import { useSubscription } from '../subscription';
import { CheckoutButtonProps } from './PricingGrid';
import {
  AlignBottom,
  CardWrapper,
  DescriptionTypography,
  LicenseBottomBox,
  LicenseMiddleBox,
  LicenseTitle,
  LicenseTopBox,
  Price,
  PriceSmall,
} from './ProductCardComponents';
import { isPaidProduct, isTrialProduct, Product } from './types';

export interface ProductCardProps {
  product: Product;
  checkoutButton: React.ComponentType<CheckoutButtonProps>;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  checkoutButton: CheckoutButton,
}) => {
  const { addSubscription } = useSubscription();
  const currency = useCurrency();
  const history = useHistory();
  const { t } = useTranslation();
  const currentProduct = product;
  const { name } = currentProduct;
  const buttonId = 'checkout-button-' + name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
  const licenseTypeDescription = useLicenseTypeDescription(name);
  const { resetInvoice } = useInvoice();
  const updateValues = () => {
    if (!isPaidProduct(currentProduct)) {
      return;
    }
    const { name, ratePlanId, productDescription } = currentProduct;
    addSubscription({
      ratePlanId,
      name,
      billingCycle: productDescription?.billingCycle,
    });
    resetInvoice();
    history.push(paths.CART);
  };

  return (
    <CardWrapper variant={'outlined'}>
      <LicenseTopBox container alignContent='flex-start' style={licenseTypeDescription.style}>
        <Grid item xs={12}>
          <LicenseTitle>{t(name)}</LicenseTitle>
        </Grid>
      </LicenseTopBox>
      <LicenseMiddleBox>
        {isPaidProduct(currentProduct) && <Price product={currentProduct} currency={currency} />}
        {isTrialProduct(currentProduct) && (
          <>
            <PriceSmall>Free</PriceSmall>
            <DescriptionTypography>&nbsp;</DescriptionTypography>
          </>
        )}
      </LicenseMiddleBox>
      <LicenseBottomBox>
        {isPaidProduct(currentProduct) && (
          <DescriptionTypography>
            <Trans i18nKey={`${name} description`} components={{ bold: <strong /> }}>
              {t(`${name} description`)}
            </Trans>
          </DescriptionTypography>
        )}
        <AlignBottom>
          <CheckoutButton buttonId={buttonId} onClick={updateValues} />
        </AlignBottom>
      </LicenseBottomBox>
    </CardWrapper>
  );
};

const smallHeight = 83;
const smallTranslate = -35;
const bigHeight = 133;
const bigTranslate = smallTranslate - (bigHeight - smallHeight);

const useLicenseTypeDescription = (name: string) => {
  switch (name) {
    case '14-Day-Trial':
      return {
        icon: trialLicense,
        iconStyle: {
          height: `${smallHeight}px`,
          transform: `translateY(${smallTranslate}px)`,
        },
        style: {
          background: '#282042',
        },
      };
    case 'Floating License Yearly':
      return {
        icon: floatingLicense,
        iconStyle: {
          height: `${bigHeight}px`,
          transform: `translateY(${bigTranslate}px)`,
        },
        style: {
          background: '#1b2042',
        },
      };
    case 'Fixed License Yearly':
    case 'Fixed License Monthly':
      return {
        icon: fixedLicense,
        iconStyle: {
          height: `${smallHeight}px`,
          transform: `translateY(${smallTranslate}px)`,
        },
        style: {
          background: '#282042',
        },
      };
    default:
      return {
        icon: fixedLicense,
        iconStyle: {
          height: `${smallHeight}px`,
          transform: `translateY(${smallTranslate}px)`,
        },
      };
  }
};
