import { Reducer } from 'react';
import { Action } from 'typescript-fsa';

export type ReducerRecord<State, T extends keyof State = keyof State> = {
  [k in T]: Reducer<State[k], Action<unknown>>;
};

export const mergeReducers = <State>(
  reducers: ReducerRecord<State>,
): Reducer<State, Action<unknown>> => {
  const entries = Object.keys(reducers) as Array<keyof State>;

  return (state, action) => {
    const newState: Partial<State> = {};
    entries.forEach((key) => {
      newState[key] = reducers[key](state[key], action);
    });

    return newState as State;
  };
};
