import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ValidationTextField from '../common/ValidationTextField';
import { useContact } from '../contact';
import { useBillingContext } from './BillingContext';
import { getHelperText } from './getHelperText';

export const Address = () => {
  const { addressVerification, inputIsLatin } = useBillingContext();
  const { address, city, zipCode } = useContact().contact;
  const { setContact } = useContact();
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <ValidationTextField
          required
          id='checkout-textfield-billing-address'
          autoComplete='street-address'
          value={address}
          onChange={(event) => setContact({ address: event.target.value })}
          helperText={getHelperText(addressVerification.error, inputIsLatin.address, t)}
          error={!!addressVerification.error || !inputIsLatin.address || !address.trim()}
          label={t('Address')}
        />
      </Grid>
      <Grid item xs={6}>
        <ValidationTextField
          required
          id='checkout-textfield-billing-city'
          autoComplete='address-level2'
          value={city}
          helperText={getHelperText(addressVerification.error, inputIsLatin.city, t)}
          error={!!addressVerification.error || !inputIsLatin.city || !city.trim()}
          onChange={(event) => setContact({ city: event.target.value })}
          label={t('City')}
        />
      </Grid>
      <Grid item xs={6} style={{ padding: '0 0 0 5px' }}>
        <ValidationTextField
          required
          id='checkout-textfield-billing-zipcode'
          autoComplete='postal-code'
          value={zipCode}
          error={!!addressVerification.error || !zipCode.trim()}
          onChange={(event) => setContact({ zipCode: event.target.value })}
          label={t('Postal Code')}
        />
      </Grid>
    </>
  );
};
