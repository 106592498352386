import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { MemoryRouter, Redirect, Route, useHistory } from 'react-router-dom';
import { useAccount } from '../account';
import { BillingContextProvider } from '../billing';
import Billing from '../billing/Billing';
import LicenseCard from '../common/LicenseCard';
import { useInvoice } from '../invoice';
import Login from '../login/Login';
import { setMonitoringUserId } from '../monitoring/monitoring';
import { cartPaths, paths } from '../navigation/UrlPaths';
import WebshopNavigationBar from '../navigation/WebshopNavigationBar';
import { usePreviewUpdates } from '../preview';
import { useSubscription } from '../subscription';
import Download from '../subscription/Download';
import { WebshopSubscriptionPayment } from '../subscriptionPayment/WebshopSubscriptionPayment';
import SuccessKnownCustomer from '../success/SuccessKnownCustomer';
import { useUserSettings } from '../userSettings';
import { routerRelativePosition, routerView } from './styling';

export { routerView } from './styling';

const initialEntries = Object.values(cartPaths);

const useOnLoginSetSentryUser = () => {
  const {
    userSettings: { isLoggedIn },
  } = useUserSettings();
  const { accountId } = useAccount().account;

  useEffect(() => {
    const id = isLoggedIn ? accountId : null;
    setMonitoringUserId(id);
  }, [isLoggedIn, accountId]);
};

const RedirectToPricing: React.FC<{
  condition: boolean;
  mainHistory: ReturnType<typeof useHistory>;
}> = ({ mainHistory, condition, children }) => {
  useEffect(() => {
    if (condition) {
      mainHistory.push(paths.PRICING);
    }
  }, [condition, mainHistory]);

  return condition ? null : <>{children}</>;
};

const RedirectToSuccess: React.FC<{ condition: boolean }> = ({ condition, children }) =>
  condition ? <Redirect to={cartPaths.SUCCESS} /> : <>{children}</>;

export const useStyles = makeStyles({
  successComponentsBox: {
    padding: 0,
    height: '100%',
  },
});

export const Cart: React.FC = () => {
  const classes = useStyles();
  const { subscription } = useSubscription();
  const { invoice } = useInvoice();
  const mainHistory = useHistory();
  const noProductSelected = !subscription.length;
  const initialIndex = 0;
  usePreviewUpdates();
  useOnLoginSetSentryUser();

  return (
    <MemoryRouter initialEntries={initialEntries} initialIndex={initialIndex}>
      <WebshopNavigationBar mainHistory={mainHistory} />
      <Route path={cartPaths.LOGIN}>
        <Grid container spacing={0}>
          <RedirectToPricing condition={noProductSelected} mainHistory={mainHistory}>
            <LicenseCard />
            <Grid item xs={12} md={6} style={routerView}>
              <Login />
            </Grid>
          </RedirectToPricing>
        </Grid>
      </Route>
      <Route path={cartPaths.BILLING}>
        <Grid container>
          <RedirectToPricing condition={noProductSelected} mainHistory={mainHistory}>
            <LicenseCard />
            <Grid item xs={12} md={6} style={routerView}>
              <BillingContextProvider>
                <Billing />
              </BillingContextProvider>
            </Grid>
          </RedirectToPricing>
        </Grid>
      </Route>
      <Route path={cartPaths.PAYMENT}>
        <RedirectToSuccess condition={Boolean(invoice.orderNumber)}>
          <Grid container style={routerRelativePosition}>
            <LicenseCard />
            <Grid item xs={12} md={6} style={routerView}>
              <WebshopSubscriptionPayment />
            </Grid>
          </Grid>
        </RedirectToSuccess>
      </Route>
      <Route path={cartPaths.SUCCESS}>
        <Box className={classes.successComponentsBox}>
          <SuccessKnownCustomer />
        </Box>
      </Route>
      <Route path={cartPaths.DOWNLOAD}>
        <Box className={classes.successComponentsBox}>
          <Download />
        </Box>
      </Route>
    </MemoryRouter>
  );
};
