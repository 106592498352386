import { Currency } from '../currency';

export const UserSettingsEvents = {
  ChangeCurrency: 'webshop.currency.change',
} as const;

declare global {
  interface WindowEventMap {
    [UserSettingsEvents.ChangeCurrency]: CustomEvent<Currency>;
  }
}
