import { Switch, withStyles } from '@material-ui/core';

const ProductSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 32,
    padding: 0,
    margin: theme.spacing(1),
    opacity: 1,
  },
  switchBase: {
    padding: 3.5,
    color: theme.palette.primary.main,
    border: 'none',
    '&$checked': {
      transform: 'translateX(29px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 32 / 2,
    backgroundColor: theme.palette.common.white,
    opacity: 1,
    transition: 'all 100ms ease-in-out',
  },
  checked: {},
}))(Switch);

export default ProductSwitch;
