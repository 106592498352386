import { InputAdornment } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import React from 'react';
import { colors } from '../ui';
import { CircularProgress } from './CircularProgress';
import { requestStatus } from './requestStatus';

const showStatus = (status: requestStatus, name = 'email') => {
  const getIcon = () => {
    switch (status) {
      case requestStatus.INVALID:
        return (
          <Close
            color='error'
            style={{ fontSize: 16 }}
            role='alert'
            aria-label={`${name} is invalid`}
          />
        );
      case requestStatus.PENDING:
        return <CircularProgress size={16} role='progressbar' />;
      case requestStatus.VERIFIED:
      case requestStatus.VALID:
        return (
          <Check
            style={{ fontSize: 16, color: colors.done }}
            role='alert'
            aria-label={`${name} is valid`}
          />
        );
      case requestStatus.EMPTY:
        return '';
      default:
        return '';
    }
  };
  return {
    endAdornment: <InputAdornment position='end'>{getIcon()}</InputAdornment>,
  };
};
export default showStatus;
