import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../assets/icons/PencilEdit.svg';
import { BillingOverview } from '../billing';
import { Logout } from '../common/Logout';
import { NewPaymentMethodDescription } from '../common/NewPaymentMethodDescription';
import { cartPaths } from '../navigation/UrlPaths';
import { PaymentErrorMessage } from '../payment/PaymentErrorMessage';
import { PaymentMethodSelect } from '../payment/PaymentMethodSelect';
import { PaymentProcessingAnimation } from '../payment/PaymentProcessingAnimation';
import { usePaymentView } from '../payment/usePaymentView';
import { EULALabel, UserAgreementCheckbox } from '../payment/UserAgreementCheckbox';
import { useSetSubscriptionPaymentData } from './useSetSubscriptionPaymentData';
import { useWebshopSubscribe } from './useWebshopSubscribe';

export const WebshopSubscriptionPayment: React.FC = () => {
  useSetSubscriptionPaymentData();
  const [PaymentView, PaymentButton] = usePaymentView();
  const { t } = useTranslation();
  const subscribe = useWebshopSubscribe();

  return (
    <>
      <PaymentProcessingAnimation />
      <BillingOverview editable>
        <Link to={cartPaths.BILLING}>
          <EditIcon style={editIconStyle} />
        </Link>
      </BillingOverview>
      <PaymentMethodSelect />
      <NewPaymentMethodDescription />
      <PaymentView />
      <UserAgreementCheckbox label={<EULALabel />} />
      <PaymentButton paymentAction={subscribe} />
      <PaymentErrorMessage label={t('Subscription failed')} />
      <Logout />
    </>
  );
};

const editIconStyle: CSSProperties = {
  position: 'absolute',
  bottom: 12,
  right: 12,
};
