import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { validateAddress } from '../api/validateAddress';
import TaxCountries from '../common/TaxCountries';
import { useContact } from '../contact';

export interface AddressVerificationStatus {
  error?: string;
  verified: boolean;
  loading: boolean;
}

const queryKey = 'addressValidation';

export const useValidateAddress = () => {
  const { setContact } = useContact();
  const { city, address, country, zipCode } = useContact().contact;
  const enabled = TaxCountries.includes(country);

  const { data, isFetching: loading } = useQuery(
    [queryKey, city, address, country, zipCode],
    () => validateAddress({ address, zip: zipCode, city, country }),
    { enabled, staleTime: Infinity },
  );

  const error = data?.reason;
  const verified = Boolean(data?.isAddressVerified);
  const state = verified && data && 'state' in data ? data.state : undefined;

  useEffect(() => setContact({ state }), [setContact, state, verified]);

  return { error, verified, loading };
};
