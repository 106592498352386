import { useConfig } from '../config';

export const usePortalHrefs = () => {
  const { accountPortal } = useConfig().authentication;
  const [auth, account, logout] = ['/service_login', '', '/logout'].map((path) => {
    const url = new URL(path, accountPortal);
    url.searchParams.set('return_to', window.location.href);
    return url.href;
  });

  return {
    auth,
    account,
    logout,
  };
};
