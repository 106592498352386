import {
  Box,
  CardContent,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme as DefaultTheme,
  Typography,
} from '@material-ui/core';
import { Delete, ExpandMore } from '@material-ui/icons';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { BillingCycle } from '@webdev/product-code';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Catalog } from '../api';
import { useGetCatalog } from '../apiQueries';
import { useCurrency } from '../context/getCurrency';
import { usePreview } from '../preview';
import { useSubscription } from '../subscription';
import { colors } from '../ui';
import {
  CardBoldTypography,
  CardEnscapeDivider,
  CardHeader,
  CardSmallTypography,
  CardWrapper,
  PreviewAnimation,
} from './CardComponents';
import { LicenseIcon } from './LicenseIcon';
import { LicenseSelect } from './LicenseSelect';
import { requestStatus } from './requestStatus';

const maxSeats = 400;
const seatsArray = Array.from(Array(maxSeats), (_, i) => i + 1);

const filterOptions = createFilterOptions<number>({
  matchFrom: 'start',
  trim: true,
  limit: 30,
});

const billingCycleInverted = Object.fromEntries(
  Object.entries(BillingCycle).map(([key, value]) => [value, key]),
);

const useStyles = makeStyles<DefaultTheme>({
  expandMoreIconBtn: {
    '& button': {
      backgroundColor: 'transparent !important',
      padding: '2px !important',
    },
  },
  note: {
    fontSize: 13,
    color: colors.primaryEnscapeOrange,
  },
});

const seatInputStyle = {
  width: '33%',
  marginBottom: '24px',
};

export const LicenseCard: React.FC<{ editableLicense?: boolean }> = ({
  editableLicense = false,
}) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { setSeats, subscription, removeSubscription } = useSubscription();
  const {
    preview: { status, value },
  } = usePreview();
  const currency = useCurrency();
  const { data, isSuccess } = useGetCatalog(null, { enabled: editableLicense });
  const verified = status === requestStatus.VERIFIED;
  const promotion = value?.invoiceItems.find((i) => i.productName === 'Promotion');

  const canEditLicense = editableLicense && isSuccess && data;
  const catalog = Object.values(data || {})
    .map(({ data }) => data?.ratePlan)
    .filter(Boolean) as Catalog;

  const products = subscription.map(({ name, seats, billingCycle, ratePlanId }, index) => (
    <React.Fragment key={index}>
      {!canEditLicense && (
        <CardHeader data-testid={`preview-box-header-${index}`}>
          <Typography variant='h3'>
            {t(name)} {<LicenseIcon type={name} />}{' '}
            {
              <IconButton onClick={() => removeSubscription(index)}>
                <Delete />
              </IconButton>
            }
          </Typography>
        </CardHeader>
      )}
      <CardContent data-testid={`preview-box-content-${index}`}>
        <Grid container spacing={0}>
          {canEditLicense && (
            <Grid item xs={12}>
              <Box marginBottom={3}>
                <LicenseSelect catalog={catalog} index={index} />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Autocomplete<number, boolean, boolean>
            id='checkout-license-seats-select'
            className={classes.expandMoreIconBtn}
            options={seatsArray}
            value={seats}
            disableClearable
            filterOptions={filterOptions}
            onChange={(_event, value) => {
              setSeats({ seats: value as number, index });
            }}
            getOptionLabel={(option) => option.toString()}
            renderOption={(option) => <>{option.toString()}</>}
            popupIcon={<ExpandMore color='primary' />}
            renderInput={(params) => (
              <TextField
                {...params}
                inputMode='numeric'
                label={t('Seats')}
                style={seatInputStyle}
                color='secondary'
                inputProps={{
                  ...params.inputProps,
                  pattern: '[0-9]*',
                  min: '1',
                  max: maxSeats,
                }}
              />
            )}
          />
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Typography>{billingCycleInverted[billingCycle || '']}</Typography>
          </Grid>
          <Grid item xs={4}>
            {verified ? (
              <Typography>
                {t('inMoney', {
                  money: JSON.stringify({
                    amount:
                      value?.invoiceItems.find(
                        (i) => i.productRatePlanId === ratePlanId && i.quantity === seats,
                      )?.chargeAmount || 0,
                    currency,
                  }),
                })}
              </Typography>
            ) : (
              <PreviewAnimation />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  ));
  return (
    <CardWrapper id='license-card'>
      {products}
      <CardContent>
        <Grid container spacing={0} data-testid={'preview-box-total'}>
          <Grid item xs={8}>
            <Typography>{t('Subtotal')}</Typography>
          </Grid>
          <Grid item xs={4}>
            {verified ? (
              <Typography>
                {t('inMoney', {
                  money: JSON.stringify({ amount: value?.totalContractedValue, currency }),
                })}
              </Typography>
            ) : (
              <PreviewAnimation />
            )}
          </Grid>
          {verified && promotion && (
            <>
              <Grid item xs={8}>
                <Typography style={{ color: colors.positiveFeedback }}>
                  {t(promotion.chargeName)}
                </Typography>
              </Grid>
              <Grid item xs={4} id='promotion-discount'>
                <Typography style={{ color: colors.positiveFeedback }}>
                  {t('inMoney', {
                    money: JSON.stringify({ amount: promotion.chargeAmount, currency }),
                  })}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={8}>
            <CardSmallTypography>{t('Taxes')}</CardSmallTypography>
          </Grid>
          <Grid item xs={4}>
            {verified ? (
              <Typography>
                {t('inMoney', { money: JSON.stringify({ amount: value?.taxAmount, currency }) })}
              </Typography>
            ) : (
              <PreviewAnimation />
            )}
          </Grid>
          <Grid item xs={12}>
            <CardEnscapeDivider />
          </Grid>
          <Grid item xs={8}>
            <CardBoldTypography>{t('Total to Pay')}</CardBoldTypography>
          </Grid>
          <Grid item xs={4}>
            {verified ? (
              <CardBoldTypography>
                {t('inMoney', { money: JSON.stringify({ amount: value?.amount, currency }) })}
              </CardBoldTypography>
            ) : (
              <PreviewAnimation />
            )}
          </Grid>

          {value?.noteKey && (
            <Grid item xs={12}>
              <FormHelperText className={classes.note}>*{t(value.noteKey)}</FormHelperText>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </CardWrapper>
  );
};

export default LicenseCard;
