import { useLoadFromLocalStorage, useLocalStoragePersistence } from '../context/statePersistence';
import { useSubscription } from './useSubscription';
import { defaultNewSubscription } from './state';

export const newSubscriptionStorageKey = 'newSubscription';

export const useSubscriptionPersistence = () =>
  useLocalStoragePersistence(newSubscriptionStorageKey, useSubscription().subscription);

export const useLoadSubscriptionPersistence = () =>
  useLoadFromLocalStorage(newSubscriptionStorageKey, defaultNewSubscription);
