import React, { PropsWithChildren, Reducer, useReducer } from 'react';
import { Action } from 'typescript-fsa';
import { storeContext } from './storeContext';

export const ContextProvider = <State,>({
  value,
  emptyState,
  reducer,
  ...props
}: PropsWithChildren<{
  value?: Partial<State>;
  emptyState: State;
  reducer: Reducer<State, Action<unknown>>;
}>) => {
  const [state, dispatch] = useReducer(reducer, { ...emptyState, ...value });
  return <storeContext.Provider value={{ state, dispatch }} {...props} />;
};
