import { useEffect, useState } from 'react';
import { WebshopMessageEvent, WebshopMessageName, WebshopMessage } from './types';

export const eventName = 'WebshopEvent';

export const dispatchWebshopMessage = (message: WebshopMessage) => {
  return window.dispatchEvent(new CustomEvent(eventName, { detail: message }));
};

export const addMessageListener = <
  N extends WebshopMessageName,
  M extends WebshopMessage = WebshopMessageMap[N]
>(
  name: N,
  callback: (message: M) => void,
) => {
  const guard = (e: WebshopMessageEvent): e is CustomEvent<M> => e.detail.type === name;
  const onMessage = (event: WebshopMessageEvent) => guard(event) && callback(event.detail);
  window.addEventListener(eventName, onMessage);

  return () => window.removeEventListener(eventName, onMessage);
};

export const addOneTimeMessageListener: typeof addMessageListener = (name, callback) => {
  const dereg = addMessageListener(name, (...args: Parameters<typeof callback>) => {
    dereg();
    return callback(...args);
  });

  return dereg;
};

export const useWebshopMessage = <
  N extends WebshopMessageName,
  M extends WebshopMessage = WebshopMessageMap[N]
>(
  name: N,
) => {
  const [state, setState] = useState<M>();

  useEffect(() => addMessageListener<N, M>(name, setState), [name]);

  return state;
};
