import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePayment } from './usePayment';

export const PayAmountLabel: React.FC = () => {
  const {
    payment: { paymentAmount, paymentCurrency },
  } = usePayment();
  const { t } = useTranslation();

  return (
    <Typography component='span'>
      {t('Pay')}{' '}
      {t('inMoney', {
        money: JSON.stringify({ amount: paymentAmount, currency: paymentCurrency }),
      })}
    </Typography>
  );
};
