import { Box, Typography } from '@material-ui/core';
import React from 'react';
import LockIcon from '../assets/icons/Lock.svg';
import ButtonPrimary from '../common/ButtonPrimary';
import { CircularProgress } from '../common/CircularProgress';
import { usePayment } from './usePayment';

export interface PayButtonProps {
  onClick: () => void;
  label: string | JSX.Element;
}

export const PayButton: React.FC<PayButtonProps> = ({ onClick, label }) => {
  const { payment } = usePayment();
  const userAgreementValidation = payment.userAgreementAccepted || !payment.userAgreementRequired;
  const isDisabled =
    payment.paymentPageIsLoading ||
    payment.showSpinner ||
    !userAgreementValidation ||
    payment.disablePaymentCount !== 0;

  return (
    <ButtonPrimary
      id='checkout-button-payment-pay'
      onClick={onClick}
      color='primary'
      disabled={isDisabled}
      style={buttonStyle}
    >
      <Box style={boxStyle}>
        {payment.showSpinner ? (
          <CircularProgress
            role='progressbar'
            style={iconStyle}
            color='inherit'
            size={19}
            thickness={5}
          />
        ) : (
          <img src={LockIcon} style={iconStyle} alt='' height={19} />
        )}
        <Typography component='span'>{label}</Typography>
      </Box>
    </ButtonPrimary>
  );
};

const iconStyle = {
  marginRight: 10,
};

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
};

const buttonStyle = {
  width: '100%',
  padding: '8px',
};
