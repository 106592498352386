import { createTheme } from '@material-ui/core';
import { createOverrides } from './overrides';
import { props } from './props';
import { breakpoints } from './breakpoints';
import { palette } from './palette';
import { typography } from './typography';

// Note1: all fonts are overwritten by wordpress (size in px)
// Note2: the base font-size of the selfservice is 10 and not 16 like in the wordpress, rem breaks the styling
// Note3: all styles defined with the '#root &' selector can break in the selfservice

export const theme = createTheme({ props, breakpoints, palette, typography });

theme.overrides = createOverrides(theme);
