import { useStoreActionDispatcher, useStoreKeyState } from '../context/storeContext';
import {
  setDefaultMethodId,
  setDefaultPaymentMethod,
  setDisablePayment,
  setDisableSelectPaymentType,
  setEnablePayment,
  setEnableSelectPaymentType,
  setPaymentAmount,
  setPaymentCountry,
  setPaymentCurrency,
  setPaymentError,
  setPaymentPageIsLoading,
  setPaymentSuccess,
  setSelectedPaymentType,
  setShowSpinner,
  setUsedPaymentType,
  setUserAgreementRequired,
  setUserPrefillData,
  startPayment,
  toggleUserAgreementAccepted,
} from './actions';
import { PaymentState } from './state';

export const usePayment = () => ({
  payment: useStoreKeyState<PaymentState>('payment'),
  setSelectedPaymentType: useStoreActionDispatcher(setSelectedPaymentType),
  setDefaultMethodId: useStoreActionDispatcher(setDefaultMethodId),
  setPaymentType: useStoreActionDispatcher(setUsedPaymentType),
  toggleUserAgreementAccepted: useStoreActionDispatcher(toggleUserAgreementAccepted),
  setShowSpinner: useStoreActionDispatcher(setShowSpinner),
  setPaymentPageIsLoading: useStoreActionDispatcher(setPaymentPageIsLoading),
  startPayment: useStoreActionDispatcher(startPayment),
  setPaymentSuccess: useStoreActionDispatcher(setPaymentSuccess),
  setPaymentError: useStoreActionDispatcher(setPaymentError),
  setDefaultPaymentMethod: useStoreActionDispatcher(setDefaultPaymentMethod),
  setUserAgreementRequired: useStoreActionDispatcher(setUserAgreementRequired),
  setDisablePayment: useStoreActionDispatcher(setDisablePayment),
  setEnablePayment: useStoreActionDispatcher(setEnablePayment),
  setPaymentAmount: useStoreActionDispatcher(setPaymentAmount),
  setPaymentCurrency: useStoreActionDispatcher(setPaymentCurrency),
  setPaymentCountry: useStoreActionDispatcher(setPaymentCountry),
  setDisableSelectPaymentType: useStoreActionDispatcher(setDisableSelectPaymentType),
  setEnableSelectPaymentType: useStoreActionDispatcher(setEnableSelectPaymentType),
  setUserPrefillData: useStoreActionDispatcher(setUserPrefillData),
});
