import { useEffect } from 'react';
import { requestStatus } from '../common/requestStatus';
import { useContact } from '../contact';
import { useCurrency } from '../context/getCurrency';
import { usePayment } from '../payment';
import { useDisablePayment } from '../payment/useDisablePayment';
import { useLoadDefaultPaymentMethod } from '../payment/useLoadDefaultPaymentMethod';
import { usePreview } from '../preview';

const useSetSubscriptionPaymentAmountData = () => {
  const {
    preview: { value },
  } = usePreview();
  const { setPaymentAmount } = usePayment();

  useEffect(() => {
    setPaymentAmount(value?.amount || 0);
  }, [value, setPaymentAmount]);
};

const useDisablePaymentWhenNoPreview = () => {
  const {
    preview: { status },
  } = usePreview();

  useDisablePayment(status !== requestStatus.VERIFIED);
};

const useSetPaymentCurrency = () => {
  const { setPaymentCurrency } = usePayment();
  const currency = useCurrency();

  useEffect(() => {
    setPaymentCurrency(currency);
  }, [currency, setPaymentCurrency]);
};

const useSetPaymentCountry = () => {
  const { setPaymentCountry } = usePayment();
  const {
    contact: { country },
  } = useContact();

  useEffect(() => {
    setPaymentCountry(country);
  }, [country, setPaymentCountry]);
};

const useSetUserPrefillData = () => {
  const { setUserPrefillData } = usePayment();
  const {
    contact: { email, firstName, lastName },
  } = useContact();

  useEffect(() => {
    setUserPrefillData({
      email,
      firstName,
      lastName,
      creditCardHolderName: `${firstName} ${lastName}`,
    });
  }, [email, firstName, lastName, setUserPrefillData]);
};

export const useSetSubscriptionPaymentData = () => {
  useDisablePaymentWhenNoPreview();
  useSetSubscriptionPaymentAmountData();
  useSetPaymentCurrency();
  useSetPaymentCountry();
  useLoadDefaultPaymentMethod();
  useSetUserPrefillData();
};
