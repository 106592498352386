import { Box, withStyles } from '@material-ui/core';
import * as React from 'react';

export const routerView: React.CSSProperties = {
  padding: 24,
};

export const routerRelativePosition: React.CSSProperties = {
  position: 'relative',
};

export const ContainerBox = withStyles({
  root: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '@media (max-width: 599px)': {
      // mobile
      overflow: 'scroll',
    },
  },
})(Box);
