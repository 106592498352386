import React from 'react';
import { PaymentMethod } from '../api/types';
import { DefaultPayment, DefaultPaymentPayButton } from './DefaultPayment';
import { PayPalPayButton } from './PayPalPayButton';
import { PayPalPayment } from './PayPalPayment';
import { Payment } from './state';
import { usePayment } from './usePayment';
import { ZuoraPayButton, ZuoraPayment } from './ZuoraPayment';

export interface PayButtonActionProps {
  paymentAction: (paymentMethod: PaymentMethod) => void;
}

export const Empty: React.FC = () => <></>;

const paymentViewMap: Record<
  Payment['selectedPaymentMethodType'],
  [React.ElementType, React.ElementType<PayButtonActionProps>]
> = {
  ACH: [ZuoraPayment, ZuoraPayButton],
  CreditCard: [ZuoraPayment, ZuoraPayButton],
  default: [DefaultPayment, DefaultPaymentPayButton],
  PayPal: [PayPalPayment, PayPalPayButton],
  SEPA: [ZuoraPayment, ZuoraPayButton],
  Other: [Empty, Empty],
};

export const usePaymentView = (): [React.ElementType, React.ElementType<PayButtonActionProps>] => {
  const { payment } = usePayment();
  return paymentViewMap[payment.selectedPaymentMethodType];
};
