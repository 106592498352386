import React from 'react';
import { ReactComponent as FixedLicenseSmall } from '../assets/icons/FixedLicenseSmall.svg';
import { ReactComponent as FloatingLicenseSmall } from '../assets/icons/FloatingLicenseSmall.svg';

export const LicenseIcon: React.FC<{ type: string }> = ({ type }) => {
  if (type === 'Fixed License Yearly' || type === 'Fixed License Monthly') {
    return <FixedLicenseSmall style={align} height={24} width={24} />;
  }
  if (type === 'Floating License Yearly') {
    return <FloatingLicenseSmall style={align} height={24} width={24} />;
  }
  return null;
};

const align = {
  verticalAlign: 'middle',
};
