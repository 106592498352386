import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonPrimary from '../common/ButtonPrimary';
import { CircularProgress } from '../common/CircularProgress';
import { Logout } from '../common/Logout';
import { requestStatus } from '../common/requestStatus';
import TextField from '../common/TextField';
import ValidationTextField from '../common/ValidationTextField';
import { useContact } from '../contact';
import { BlockedCountryMessage, useIsBlockedCountry } from '../login/BlockedCountries';
import { cartPaths } from '../navigation/UrlPaths';
import { usePreview } from '../preview';
import { Address } from './Address';
import { useBillingContext } from './BillingContext';
import { CountrySelector } from './CountrySelector';
import { getHelperText } from './getHelperText';
import { VATField } from './VATField';

/**
 * @desc UI-Component to gather user information during the checkout process. The user addds his name, address and Email within Textfields.
 * */

const useStyles = makeStyles({
  btnMarginTop: {
    margin: '20px 0 0 0',
  },
  marginTop: {
    margin: '40px 0 0 0',
  },
});

const Billing = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    preview: { status },
  } = usePreview();
  const {
    contact: { email, firstName, lastName, country, company, address, city, zipCode },
    setContact,
  } = useContact();

  const { vatStatus, inputIsLatin, addressVerification } = useBillingContext();

  const isBlockedCountry = useIsBlockedCountry();
  const vatIsValid = vatStatus === requestStatus.VALID || vatStatus === requestStatus.EMPTY;
  const previewIsValid = status === requestStatus.VERIFIED;
  const allInputsAreLatin = Object.values(inputIsLatin).every(Boolean);
  const allRequiredInputsAreThere = [
    firstName,
    lastName,
    address,
    zipCode,
    city,
    country,
  ].every((inputValue) => inputValue.trim());
  const formIsValid =
    allRequiredInputsAreThere &&
    vatIsValid &&
    allInputsAreLatin &&
    previewIsValid &&
    !addressVerification.error &&
    !isBlockedCountry;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!formIsValid) {
      return;
    }

    history.push(cartPaths.PAYMENT);
  };

  return (
    <Box>
      <Typography variant='h3' id='billingInformationTitle'>
        {t('Billing Information')}
      </Typography>
      <form noValidate autoComplete='on' onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id='checkout-textfield-billing-email'
              label={t('Email')}
              value={email}
              onChange={(event) => setContact({ email: event.target.value })}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <ValidationTextField
              required
              id='checkout-textfield-billing-firstname'
              autoComplete='given-name'
              label={t('First Name')}
              value={firstName}
              onChange={(event) => setContact({ firstName: event.target.value })}
              autoFocus
              error={!inputIsLatin.firstName || !firstName.trim()}
              helperText={getHelperText('', inputIsLatin.firstName, t)}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidationTextField
              required
              id='checkout-textfield-billing-lastname'
              autoComplete='family-name'
              label={t('Last Name')}
              value={lastName}
              onChange={(event) => setContact({ lastName: event.target.value })}
              error={!inputIsLatin.lastName || !lastName.trim()}
              helperText={getHelperText('', inputIsLatin.lastName, t)}
            />
          </Grid>
          <Address />
          <Grid item xs={12}>
            <CountrySelector />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h3' className={classes.marginTop}>
              {t('Optional')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VATField />
          </Grid>
          <Grid item xs={12}>
            <ValidationTextField
              id='checkout-textfield-billing-company'
              autoComplete='organization'
              label={t('Company')}
              value={company}
              onChange={(event) => setContact({ company: event.target.value })}
              error={!inputIsLatin.company}
              helperText={getHelperText('', inputIsLatin.company, t)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.btnMarginTop} style={{ textAlign: 'center' }}>
          <BillingButton
            showSpinner={addressVerification.loading || vatStatus === requestStatus.PENDING}
            disabled={!formIsValid}
          >
            {t('Go to payment')}
          </BillingButton>
          {isBlockedCountry && <BlockedCountryMessage />}
        </Grid>
      </form>
      <Grid style={{ textAlign: 'right' }}>
        <Logout />
      </Grid>
    </Box>
  );
};

const BillingButton: React.FC<{ showSpinner: boolean; disabled: boolean }> = ({
  showSpinner,
  children,
  disabled,
}) => {
  return (
    <ButtonPrimary
      fullWidth
      id='checkout-button-billing-payment'
      type='submit'
      color='primary'
      disabled={showSpinner || disabled}
    >
      <Box style={boxStyle}>
        {children}
        {showSpinner ? (
          <CircularProgress
            style={iconStyle}
            color='inherit'
            size={19}
            thickness={5}
            role='progressbar'
          />
        ) : null}
      </Box>
    </ButtonPrimary>
  );
};

const iconStyle = {
  marginLeft: 10,
};

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
};

Billing.displayName = 'Billing';
export default Billing;
