import React, { createContext, useCallback, useContext } from 'react';
import { Action, ActionCreator } from 'typescript-fsa';
import { definedOrThrows } from '../utils/definedOrThrows';

type DispatchPayload = Action<unknown>;

export const storeContext = createContext<
  { state: unknown; dispatch: React.Dispatch<DispatchPayload> } | undefined
>(undefined);

export const useStoreActionDispatcher = <P>(actionsCreator: ActionCreator<P>) => {
  const { dispatch } = definedOrThrows(useContext(storeContext));
  return useCallback((payload: P) => dispatch(actionsCreator(payload)), [dispatch, actionsCreator]);
};
export const useStoreKeyState = <State>(key: keyof State) => {
  const { state } = definedOrThrows(useContext(storeContext)) as {
    state: State;
    dispatch: React.Dispatch<DispatchPayload> | undefined;
  };
  return state[key];
};
