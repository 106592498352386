import { useEffect } from 'react';

export const useWindowEvent = <K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
) => {
  useEffect(() => {
    window.addEventListener<K>(type, listener, options);
    return () => window.removeEventListener<K>(type, listener, options);
  }, [listener, options, type]);
};
