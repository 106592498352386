import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import downloadIconOrange from '../assets/icons/DownloadIconOrange.svg';
import { colors } from '../ui';
import ButtonPrimary from './ButtonPrimary';

export interface ButtonDownloadProps {
  href: string;
  label: string;
}

const linkProps: any = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const ButtonDownload: FunctionComponent<ButtonDownloadProps> = ({ href, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ButtonPrimary color='primary' href={href} className={classes.buttonStyle} {...linkProps}>
        <div className={classes.iconStyle}></div>
        <div className={classes.buttonLabelStyle}>{label}</div>
      </ButtonPrimary>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    background: 'transparent',
  },
  buttonLabelStyle: {
    padding: 10,
    color: colors.secondaryEnscapeOrange,
    textAlign: 'center',
    textTransform: 'uppercase',
    borderRadius: 0,
    border: `${colors.secondaryEnscapeOrange} solid 1px`,
    backgroundColor: 'white',
    maxHeight: 45,
    width: '100%',
    boxSizing: 'border-box',
  },
  iconStyle: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderRadius: 0,
    border: `${colors.secondaryEnscapeOrange} solid`,
    borderWidth: '1px 0 1px 1px',
    width: '50px',
    maxHeight: 45,
    backgroundImage: `url(${downloadIconOrange})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  buttonStyle: {
    width: '100%',
    boxShadow: 'none',
    margin: '8px 24px',
    marginLeft: 0,
    padding: 0,
    whiteSpace: 'nowrap',
    fontSize: 16,
    backgroundColor: 'white',
    boxSizing: 'border-box',
    '&:hover div, &:focus div': {
      backgroundColor: colors.tertiaryOrangeHover,
    },
    '& span': {
      alignItems: 'unset',
    },
  },
});

export default ButtonDownload;
