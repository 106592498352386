const EUCountries = [
  'al',
  'ad',
  'am',
  'at',
  'by',
  'be',
  'ba',
  'bg',
  'ch',
  'cy',
  'cz',
  'de',
  'dk',
  'ee',
  'es',
  'fo',
  'fi',
  'fr',
  'ge',
  'gi',
  'gr',
  'hu',
  'hr',
  'ie',
  'is',
  'it',
  'lt',
  'lu',
  'lv',
  'mc',
  'mk',
  'mt',
  'no',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'se',
  'se',
  'sk',
  'sm',
  'tr',
  'ua',
  'va',
];

export default EUCountries;
