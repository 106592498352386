import { InputAdornment, TextFieldProps } from '@material-ui/core';
import { Check, Error } from '@material-ui/icons';
import React from 'react';
import { colors } from '../ui';
import TextField from './TextField';

type ValidationTextFieldProps = JSX.IntrinsicAttributes &
  React.RefAttributes<HTMLInputElement> &
  TextFieldProps;

const ValidationTextField = (props: ValidationTextFieldProps) => (
  <TextField
    {...props}
    fullWidth
    color={props.value ? 'secondary' : 'primary'}
    InputProps={{
      ...props.InputProps,
      endAdornment: <InputAdornment position='end'>{getIcon(props)}</InputAdornment>,
    }}
  >
    {props.children}
  </TextField>
);

const getIcon = (props: ValidationTextFieldProps) => {
  if (props.error) {
    return <Error color='error' style={iconStyle} />;
  }
  if (props.value) {
    return <Check style={iconStyle} />;
  }
  return '';
};

const iconStyle = { fontSize: 16, color: colors.done };

export default ValidationTextField;
