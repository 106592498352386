import { AppBar, Avatar, styled, Typography, withStyles } from '@material-ui/core';
import { ChevronLeft, Clear } from '@material-ui/icons';

export const NavigationBackButtonIcon = withStyles({
  root: {
    cursor: 'pointer',
    fontSize: 40,
  },
})(ChevronLeft);
export const NavigationCloseButtonIcon = withStyles({
  root: {
    cursor: 'pointer',
    fontSize: 30,
    marginLeft: 8,
  },
})(Clear);

export const NavigationBar = styled(AppBar)({
  '#root &': {
    zIndex: 10000,
    position: 'sticky',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
    height: 60,
    background: 'linear-gradient(271.85deg, #F15A24, #EF9604)',
  },
});

export const NavigationWhiteCircle = styled(Avatar)({
  '#root &': {
    fontSize: 18,
    height: 25,
    width: 25,
    color: '#EF9604',
    background: 'white',
  },
});

export const NavigationTitle = styled(Typography)({
  '#root &': {
    color: 'white',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    fontSize: 16,
    padding: '0 10px',
  },
});
